export const CloseIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1518_4063)">
        <path d="M38 12.82L35.18 10L24 21.18L12.82 10L10 12.82L21.18 24L10 35.18L12.82 38L24 26.82L35.18 38L38 35.18L26.82 24L38 12.82Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1518_4063">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export const CheckIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1518_4076)">
        <path d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40ZM33.18 15.16L20 28.34L14.82 23.18L12 26L20 34L36 18L33.18 15.16Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1518_4076">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const RUBIcon: React.FC = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4897)">
        <path d="M8.40803 5.5H10.796V19.5H8.40803V5.5ZM9.75251 7.58V5.5H12.7224C13.7525 5.5 14.6622 5.64 15.4515 5.92C16.2408 6.18667 16.8629 6.62667 17.3177 7.24C17.7726 7.84 18 8.64 18 9.64C18 10.6267 17.7726 11.4333 17.3177 12.06C16.8629 12.6733 16.2408 13.12 15.4515 13.4C14.6622 13.6667 13.7525 13.8 12.7224 13.8H9.75251V11.72H12.7224C13.3512 11.72 13.8863 11.6533 14.3278 11.52C14.7692 11.3733 15.1171 11.1467 15.3712 10.84C15.6254 10.5333 15.7525 10.1333 15.7525 9.64C15.7525 9.16 15.6254 8.76667 15.3712 8.46C15.1171 8.15333 14.7692 7.93333 14.3278 7.8C13.8863 7.65333 13.3512 7.58 12.7224 7.58H9.75251ZM10.5151 17.62V15.68H14.6288V17.62H10.5151ZM6 13.74V11.8H10.1137V13.74H6ZM6 17.62V15.68H10.1137V17.62H6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4897">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}