import React from 'react'
import type { CSSProperties } from "react";
import { IResourceComponentsProps, useShow, useTranslate } from '@refinedev/core'
import { Flex, Spin } from 'antd'
import { IOperation } from 'interfaces'
import { motion } from "framer-motion"
import { Wrapper } from 'components/client_ui/Wrapper'
import { TextJost } from 'components/client_ui/TextJost'
import { RUBIcon, CloseIcon, CheckIcon } from './icons'
import { HomeButton } from 'components/client_ui/HomeButton'
import { formatAmount, statusColor } from 'components/client_ui/utils'

const apruvedStyle: CSSProperties = {
  background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
  boxShadow: '0px 0px 120px 0px #75B3FF',
}
const errorStyle: CSSProperties = {
  background: '#F28754',
  boxShadow: '0px 0px 120px 0px #F28754',
}

export const CurrencyIcon: React.FC<{ currency?: string; }> = ({ currency }) => {
  switch (currency?.toUpperCase()) {
    case 'RUB':
      return <RUBIcon />
    default:
      return null
  }
}

export const OperationShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IOperation>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const iconStyle = record?.status === 'approved' ? apruvedStyle : errorStyle

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 32px 10px 32px',
          minHeight: '100vh',
        }}
      >
        {isLoading &&
          <Spin
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 5,
            }}
          />
        }

        {!isLoading && record &&
          <>
            <Flex
              vertical
              gap={64}>
              <Flex
                justify='center'
                align='center'
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: '50%',
                  margin: '0 auto',
                  ...iconStyle,
                }}
              >
                {record.status === 'approved' ? <CheckIcon /> : <CloseIcon />}
              </Flex>
              <Flex
                vertical
                gap={42}
                style={{
                  margin: '0 auto'
                }}
              >
                <Flex
                  vertical
                  gap={16}
                >
                  <TextJost
                    fontWeight='500'
                    style={{
                      fontSize: 16,
                      color: statusColor(record.status),
                      margin: '0 auto'
                    }}
                  >
                    {t('components.operations_list.statuses.' + record.status)}
                  </TextJost>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.withdraw.titles.amount')}</TextJost>
                    <Flex
                      align='center'
                    >
                      <TextJost fontSize={20} fontWeight='800'>{formatAmount(record.amount)}</TextJost>
                      <CurrencyIcon currency={record.currency?.code} />
                    </Flex>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.withdraw.titles.payment_method')}</TextJost>
                    <Flex
                      vertical
                    >
                      <TextJost fontSize={18} fontWeight='800'>
                        {t('pages.deposit.payment_methods.' + record.gateway?.payment_type)}
                      </TextJost>
                      <TextJost fontSize={16} fontWeight='700'>
                        {record.gateway?.name}
                      </TextJost>
                    </Flex>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.withdraw.titles.operation_id')}</TextJost>
                    <TextJost fontSize={12} fontWeight='800'>{record.id}</TextJost>
                  </Flex>
                </Flex>
                <HomeButton />
              </Flex>
            </Flex>
          </>
        }
      </Wrapper>
    </motion.div>
  )
}
