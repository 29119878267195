import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Descriptions, Button, Modal, Typography, Alert, Popconfirm, Card } from 'antd'
import { useApiUrl, useCustom, useCustomMutation, useTranslate } from '@refinedev/core'
import { ISecretKey } from 'interfaces'
import DateField from 'components/DateField'

export const SecretKey: React.FC = () => {
  const t = useTranslate()
  const queryClient = useQueryClient()
  const apiUrl = useApiUrl()
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [value, setValue] = useState('')

  const { mutate } = useCustomMutation<ISecretKey>()

  const { data, isLoading } = useCustom<ISecretKey>({
    url: `${apiUrl}/secret_key`,
    method: 'get',
    queryOptions: {
      queryKey: ['secret_key', 'current'],
    },
  })

  const onCreate = () => {
    mutate(
      {
        url: `${apiUrl}/secret_key`,
        method: 'post',
        values: {},
      },
      {
        onError: (error, variables, context) => {
          setLoading(false)
        },
        onSuccess: (data, variables, context) => {
          setValue(data.data.value)
          setModalOpen(true)
          setLoading(false)
        },
      }
    )
  }

  const createButton = (
    <>
      {data?.data.value ? (
        <Popconfirm
          title={t('pages.api_tokens.secret_key.create.confirm.title')}
          description={t('pages.api_tokens.secret_key.create.confirm.description')}
          onConfirm={onCreate}
          okText={t('pages.api_tokens.secret_key.create.confirm.ok')}
          cancelText={t('pages.api_tokens.secret_key.create.confirm.cancel')}
        >
          <Button loading={loading} type="primary">
            {t('pages.api_tokens.secret_key.generate')}
          </Button>
        </Popconfirm>
      ) : (
        <Button loading={loading} onClick={onCreate} type="primary">
          {t('pages.api_tokens.secret_key.generate')}
        </Button>
      )}
      <Modal
        open={modalOpen}
        title={t('pages.api_tokens.secret_key.create.title')}
        onCancel={() => {
          queryClient.invalidateQueries(['secret_key', 'current'])
          setModalOpen(false)
        }}
        footer={null}
      >
        <Alert message={t('pages.api_tokens.secret_key.create.warning')} type="warning" />
        <Typography.Title code copyable style={{ marginTop: 16 }} level={5}>
          {value}
        </Typography.Title>
      </Modal>
    </>
  )

  if (isLoading) return <></>

  return (
    <Card title={t('pages.api_tokens.secret_key.title')} extra={createButton}>
      <Descriptions>
        {data?.data.value && (
          <>
            <Descriptions.Item label={t('pages.api_tokens.secret_key.fields.value')}>
              {data.data.value}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.api_tokens.secret_key.fields.created_at')}>
              <DateField value={data.data.created_at} />
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
      <Alert message={t('pages.api_tokens.secret_key.info')} type="info" />
    </Card>
  )
}
