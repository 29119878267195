import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
} from '@refinedev/core'

import { List, useTable } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IMerchant } from 'interfaces'
import { SearchOutlined } from '@ant-design/icons'
import MerchantsTable from './list_table'

export const MerchantList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { tableProps, searchFormProps, filters } = useTable<IMerchant>({
    onSearch: (params: any) => {
      const { id, search } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'id',
        operator: 'eq',
        value: id,
      })

      filters.push({
        field: 'search',
        operator: 'in',
        value: search,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })

  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={data?.includes('admin')}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          id: getDefaultFilter('id', filters),
          search: getDefaultFilter('search', filters),
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            name="id"
            style={{
              flexGrow: 1,
              marginRight: 16,
            }}
          >
            <Input
              placeholder={t('pages.merchants.filter.id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="search"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.merchants.filter.search.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
      </Form>
      <MerchantsTable tableProps={tableProps} />
    </List>
  )
}
