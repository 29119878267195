import { USER_KEY } from "../../constants"

const userData = localStorage.getItem(USER_KEY);
const roles = userData ? JSON.parse(userData)?.roles : [];

export const IsManagerRole = (): boolean => {
  const manager_roles = [
    'merchant_admin',
    'support',
    'admin',
    'developer',
  ];

  for (let i = 0; i < roles.length; i++) {
    if (manager_roles.includes(roles[i])) {
      return true;
    }
  }

  return false;
}