const PrimaryIcon: React.FC = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill="url(#paint0_linear_1594_17598)" />
      <defs>
        <linearGradient id="paint0_linear_1594_17598" x1="8" y1="-1.23447e-06" x2="-1.26712" y2="1.93664" gradientUnits="userSpaceOnUse">
          <stop stopColor="#76D6FF" />
          <stop offset="1" stopColor="#7280FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Icon: React.FC = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill="#EFEFEF" />
    </svg>
  );
};

export const Paginator: React.FC = () => {
  return (
    <div
      style={{
        height: 8,
        width: 44,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 4,
      }}
    >
      <PrimaryIcon />
      <Icon />
      <Icon />
      <Icon />
    </div>
  )
}