import React from "react";

export const CreditCardIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4869)">
        <path d="M16.6666 3.3335H3.33329C2.40829 3.3335 1.67496 4.07516 1.67496 5.00016L1.66663 15.0002C1.66663 15.9252 2.40829 16.6668 3.33329 16.6668H16.6666C17.5916 16.6668 18.3333 15.9252 18.3333 15.0002V5.00016C18.3333 4.07516 17.5916 3.3335 16.6666 3.3335ZM16.6666 15.0002H3.33329V10.0002H16.6666V15.0002ZM16.6666 6.66683H3.33329V5.00016H16.6666V6.66683Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NewCardIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4792)">
        <path d="M10.8334 5.83317H9.16669V9.1665H5.83335V10.8332H9.16669V14.1665H10.8334V10.8332H14.1667V9.1665H10.8334V5.83317ZM10 1.6665C5.40002 1.6665 1.66669 5.39984 1.66669 9.99984C1.66669 14.5998 5.40002 18.3332 10 18.3332C14.6 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6 1.6665 10 1.6665ZM10 16.6665C6.32502 16.6665 3.33335 13.6748 3.33335 9.99984C3.33335 6.32484 6.32502 3.33317 10 3.33317C13.675 3.33317 16.6667 6.32484 16.6667 9.99984C16.6667 13.6748 13.675 16.6665 10 16.6665Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4792">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};