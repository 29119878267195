import { AuthBindings } from '@refinedev/core'

import {
  TOKEN_KEY,
  USER_KEY,
  BACKEND_URL,
  TWOFA_KEY,
  SIGNUP_ID_KEY,
  SIGNUP_TOKEN_KEY,
  SIGNUP_PASSWORD_KEY,
  SIGNUP_PHONE_KEY,
  SIGNUP_FINISH_KEY
} from './constants'

import axios, { AxiosInstance } from 'axios'

export const authProvider = (axiosInstance: AxiosInstance): AuthBindings => {
  let apiUrl = 'http://localhost:3001'

  if (BACKEND_URL) {
    apiUrl = BACKEND_URL
  }

  return {
    register: async ({ phone = null, token = null, password = null }) => {
      phone = phone ? phone.replace(/\D/g, '') : null;

      const signup_id = localStorage.getItem(SIGNUP_ID_KEY)
      const signup_finish = localStorage.getItem(SIGNUP_FINISH_KEY)

      if (signup_finish) {
        localStorage.removeItem(SIGNUP_FINISH_KEY)

        return {
          success: true,
          redirectTo: '/home',
        }
      } else if (password && signup_id) {
        try {
          await axios.post(
            `${apiUrl}/signup/${signup_id}/password`,
            { password }
          )

          // логинимся сразу после успешной регистрации
          const saved_phone = localStorage.getItem(SIGNUP_PHONE_KEY)
          if (saved_phone) {
            const { data } = await axios.post(`${apiUrl}/sessions`, {
              phone: saved_phone,
              password,
              lifespan: 30,
            })
            localStorage.setItem(TOKEN_KEY, data.token)
            localStorage.setItem(
              TWOFA_KEY,
              data.two_factor_verification_required ? 'required' : 'not_required'
            )

            if (!data.two_factor_verification_required) {
              const userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
              localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
            }
          }

          localStorage.removeItem(SIGNUP_ID_KEY)
          localStorage.removeItem(SIGNUP_TOKEN_KEY)
          localStorage.removeItem(SIGNUP_PASSWORD_KEY)
          localStorage.removeItem(SIGNUP_PHONE_KEY)

          localStorage.setItem(SIGNUP_FINISH_KEY, 'required')

          return {
            success: true,
            redirectTo: '/signup',
          }
        } catch (error: any) {
          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      } else if (token && signup_id) {
        try {
          await axios.post(
            `${apiUrl}/signup/${signup_id}/confirm_otp`,
            { token }
          )

          localStorage.setItem(SIGNUP_TOKEN_KEY, 'not_required')
          localStorage.setItem(SIGNUP_PASSWORD_KEY, 'required')

          return {
            success: true,
            redirectTo: '/signup',
          }
        } catch (error: any) {
          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      } else {
        try {
          const { data } = await axios.post(
            `${apiUrl}/signup/phone`,
            { phone }
          )

          localStorage.setItem(SIGNUP_PHONE_KEY, phone)
          localStorage.setItem(SIGNUP_ID_KEY, data.id)
          localStorage.setItem(SIGNUP_TOKEN_KEY, 'required')

          return {
            success: true,
            redirectTo: '/signup',
          }
        } catch (error: any) {
          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      }
    },
    login: async ({ phone = null, password = null, code = null }) => {
      phone = phone ? phone.replace(/\D/g, '') : null;

      const signup_id = localStorage.getItem(SIGNUP_ID_KEY)
      if (signup_id) {
        localStorage.removeItem(SIGNUP_ID_KEY)
        localStorage.removeItem(SIGNUP_TOKEN_KEY)
        localStorage.removeItem(SIGNUP_PASSWORD_KEY)
        localStorage.removeItem(SIGNUP_PHONE_KEY)
      }

      if (code) {
        try {
          await axiosInstance.post(
            `${apiUrl}/sessions/verify`,
            { code }
          )
          localStorage.setItem(TWOFA_KEY, 'passed')

          const userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
          localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))

          return {
            success: true,
            redirectTo: '/',
          }
        } catch (error: any) {
          // console.log('error.response?.data', error.response?.data)

          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      } else {
        try {
          const { data } = await axios.post(`${apiUrl}/sessions`, {
            phone,
            password,
            lifespan: 30,
          })
          localStorage.setItem(TOKEN_KEY, data.token)
          localStorage.setItem(
            TWOFA_KEY,
            data.two_factor_verification_required ? 'required' : 'not_required'
          )

          if (!data.two_factor_verification_required) {
            const userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
            localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
          }

          return {
            success: true,
            redirectTo: '/',
          }
        } catch (error: any) {
          return {
            success: false,
            error: {
              message: 'Ошибка',
              name: error.response?.data?.errors?.join(', '),
            },
          }
        }
      }
    },
    logout: async () => {
      const token = localStorage.getItem(TOKEN_KEY)
      const signup_id = localStorage.getItem(SIGNUP_ID_KEY)

      if (signup_id) {
        localStorage.removeItem(SIGNUP_ID_KEY)
        localStorage.removeItem(SIGNUP_TOKEN_KEY)
        localStorage.removeItem(SIGNUP_PASSWORD_KEY)
        localStorage.removeItem(SIGNUP_PHONE_KEY)
        return {
          success: true,
          redirectTo: '/signup',
        }
      }

      if (token) {
        try {
          await axiosInstance.delete(`${apiUrl}/sessions/logout`)
        } catch { }

        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)
        localStorage.removeItem(TWOFA_KEY)
        return {
          success: true,
          redirectTo: '/login',
        }
      }

      return {
        success: true,
        redirectTo: '/login',
      }
    },
    check: async () => {
      const token = localStorage.getItem(TOKEN_KEY)
      const two_fa = localStorage.getItem(TWOFA_KEY)
      const signup_id = localStorage.getItem(SIGNUP_ID_KEY)
      const signup_token = localStorage.getItem(SIGNUP_TOKEN_KEY)
      const signup_password = localStorage.getItem(SIGNUP_PASSWORD_KEY)
      const signup_finish = localStorage.getItem(SIGNUP_FINISH_KEY)

      if (signup_finish && signup_finish === 'required') {
        return {
          authenticated: false,
          redirectTo: '/finish_required',
        }
      }

      if (signup_id) {
        if (signup_token && signup_token === 'required') {
          return {
            authenticated: false,
            redirectTo: '/confirm_otp_required',
          }
        }

        if (signup_password && signup_password === 'required') {
          return {
            authenticated: false,
            redirectTo: '/password_required',
          }
        }
      }

      if (token) {
        if (two_fa && two_fa === 'required') {
          return {
            authenticated: false,
            redirectTo: '/two_factor',
          }
        } else {
          return {
            authenticated: true,
          }
        }
      }

      return {
        authenticated: false,
        redirectTo: '/login',
      }
    },
    getPermissions: async () => {
      const userInfo = localStorage.getItem(USER_KEY)
      if (userInfo) {
        const parsedUser = JSON.parse(userInfo)
        return parsedUser.roles
      }
      return { success: false }
    },
    getIdentity: async () => {
      try {
        const token = localStorage.getItem(TOKEN_KEY)
        const two_fa = localStorage.getItem(TWOFA_KEY)
        if (token && (two_fa ? two_fa === 'passed' || two_fa === 'not_required' : true)) {
          let userInfo = localStorage.getItem(USER_KEY)
          if (userInfo) {
            const parsedUser = JSON.parse(userInfo)
            return parsedUser
          } else {
            let userInfo = await axiosInstance.get(`${apiUrl}/users/current`)
            localStorage.setItem(USER_KEY, JSON.stringify(userInfo.data))
            return userInfo.data
          }
        } else {
          return {}
        }
      } catch (error: any) {
        return { error }
      }
    },
    onError: async (error) => {
      if (error.statusCode === 401) {
        return {
          redirectTo: '/login',
          logout: true,
          error,
        }
      }
      return { error }
    },
  }
}
