import { useNavigate } from "react-router-dom";
import { useLogout, useTranslate } from '@refinedev/core'
import { Flex, Typography } from 'antd'

const { Text } = Typography

const ExitIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6887)">
        <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ExitBtn: React.FC<{
  style?: React.CSSProperties
}> = ({
  style = {}
}) => {
    const t = useTranslate()
    // const navigate = useNavigate();
    const { mutate: mutateLogout } = useLogout()

    return (
      <Flex
        // onClick={() => navigate('/home')}
        onClick={() => mutateLogout()}
        justify='center'
        align='center'
        gap={8}
        style={Object.assign({
          height: 24,
          cursor: 'pointer',
        }, style)}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '400',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {t('components.exit_button.titles.exit')}
        </Text>
        <ExitIcon />
      </Flex>
    )
  }