import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, Space, TableProps, Tag, Typography } from 'antd'
import { ITariff } from 'interfaces'
import { ShortId } from 'components/ShortId'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import DateField from 'components/DateField'
import { TariffKind } from 'components/TariffKind'

const { Text } = Typography

const TariffsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<ITariff> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<ITariff>
        ellipsis
        width={100}
        dataIndex="id"
        key="id"
        title={t('pages.admin_tariffs.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ITariff>
        width={300}
        dataIndex="kind"
        key="kind"
        title={t('pages.admin_tariffs.fields.kind')}
        render={(value) => <TariffKind kind={value} />}
      />
      <Table.Column<ITariff>
        ellipsis
        width={150}
        dataIndex="valid_from"
        key="valid_from"
        title={t('pages.admin_tariffs.fields.valid_from')}
        render={(value) => <DateField value={value} format='YYYY.MM.DD' />}
      />
      <Table.Column<ITariff>
        ellipsis
        width={150}
        dataIndex="valid_to"
        key="valid_to"
        title={t('pages.admin_tariffs.fields.valid_to')}
        render={(value) => <DateField value={value} format='YYYY.MM.DD' />}
      />
      <Table.Column<ITariff>
        ellipsis
        width={150}
        dataIndex="percentage_fee"
        key="percentage_fee"
        title={t('pages.admin_tariffs.fields.percentage_fee')}
        render={(value) => <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{value}</Text>}
      />
      <Table.Column<ITariff>
        width={350}
        dataIndex="gateway"
        key="gateway"
        title={t('pages.admin_tariffs.fields.gateway')}
        render={(value) =>
          <Space size={1}>
            <Tag>{value?.currency?.code}</Tag>
            <GatewayDirection direction={value?.direction} />
            <GatewayType type={value?.payment_type} />
            <Tag>{value?.name}</Tag>
          </Space>
        }
      />
      <Table.Column<ITariff>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_tariffs.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
    </Table>
  )
}

export default TariffsTable
