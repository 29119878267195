import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space } from 'antd'
import { IEvent } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'

const AdminEventsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IEvent> }
> = ({ tableProps }) => {
  const t = useTranslate()

  const actionColor = (action: string): string => {
    if (action?.includes('hold')) return 'blue'
    if (action?.includes('waste')) return 'orange'
    if (action?.includes('approve')) return 'green'
    if (action?.includes('expire')) return 'red'
    return 'default'
  }

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IEvent>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_events.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IEvent>
        dataIndex="payload"
        key="payload"
        title={t('pages.admin_events.fields.payload')}
        render={(value) =>
          <Tag
            color={value?.payload?.errors ? 'red' : 'geekblue'}
          >
            <pre>{JSON.stringify(value, null, 2)}</pre>
          </Tag>
        }
      />
      <Table.Column<IEvent>
        dataIndex="action"
        key="action"
        title={t('pages.admin_events.fields.action')}
        render={(value) =>
          <>
            <Tag color={actionColor(value?.code)}>{value?.code}</Tag>
            {/* <Tag color={actionColor(value?.code)}>{value?.name}</Tag> */}
          </>
        }
      />
      <Table.Column<IEvent>
        dataIndex="user"
        key="user"
        title={t('pages.admin_events.fields.user')}
        render={(value) =>
          <>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.phone && <Tag color='geekblue'><CopyText value={value?.phone} style={{ fontSize: 12 }} /></Tag>}
          </>
        }
      />
      <Table.Column<IEvent>
        dataIndex="merchant"
        key="merchant"
        title={t('pages.admin_events.fields.merchant')}
        render={(value) =>
          <>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.name && <Tag color='geekblue'>{value?.name}</Tag>}
          </>
        }
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="operation_id"
        key="operation_id"
        title={t('pages.admin_events.fields.operation_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="dispute_id"
        key="dispute_id"
        title={t('pages.admin_events.fields.dispute_id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IEvent>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_events.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      {/* <Table.Column<IEvent>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_events.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      /> */}
    </Table>
  )
}

export default AdminEventsTable
