import { Flex, Typography, Space } from "antd"
import { ICard } from "interfaces"
import { useTranslate } from "@refinedev/core"
import type { CSSProperties } from "react";
import { NewCardIcon, CreditCardIcon } from "./icons";

const { Text } = Typography

const blackBorderStyle: CSSProperties = {
  border: '1px solid #303030',
};
const blueBorderStyle: CSSProperties = {
  background: 'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%) border-box',
  border: '1px solid transparent',
};

export const ChipBankCardNew: React.FC<{
  selectCard: (card: ICard | undefined) => void
}> = ({
  selectCard
}) => {
    const t = useTranslate()

    return (
      <Flex
        align='center'
        gap={10}
        onClick={() => selectCard(undefined)}
        style={{
          ...blueBorderStyle,
          padding: 16,
          borderRadius: 10,
          cursor: 'pointer',
        }}
      >
        <Flex
          wrap
          align='center'
          gap={8}
        >
          <span
            children={<NewCardIcon />}
            style={{
              height: 20,
              padding: '0 6px',
            }}
          />
          <Text
            style={{
              height: 20,
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {t('components.chip_bank_card_new.buttons.new_card')}
          </Text>
        </Flex>
      </Flex>
    )
  }

export const ChipBankCard: React.FC<{
  card: ICard
  selected_id: string | undefined
  selectCard: (card: ICard) => void
}> = ({
  card,
  selected_id,
  selectCard
}) => {
    const hide = selected_id && selected_id !== card?.id
    const borderStyle = hide === false ? blueBorderStyle : blackBorderStyle;

    return (
      <Flex
        align='center'
        gap={10}
        onClick={() => selectCard(card)}
        style={{
          ...borderStyle,
          padding: 16,
          borderRadius: 10,
          cursor: 'pointer',
          display: hide === true ? 'none' : 'flex',
        }}
      >
        <Flex
          wrap
          align='center'
          gap={8}
        >
          <span
            children={<CreditCardIcon />}
            style={{
              height: 20,
              padding: '0 6px',
            }}
          />
          <Text
            style={{
              height: 20,
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            <Space wrap size={4}>
              <span>****</span>
              {card?.masked_number?.slice(-4)}
            </Space>
          </Text>
        </Flex>
      </Flex>
    )
  }