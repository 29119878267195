import { useEffect, useState } from "react";
import { useTranslate } from "@refinedev/core";
import { Button, FormInstance, Form, Typography, Flex, Space } from "antd";

const { Text } = Typography

const RUBIcon: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_129)">
        <path d="M8.40803 5H10.796V19H8.40803V5ZM9.75251 7.08V5H12.7224C13.7525 5 14.6622 5.14 15.4515 5.42C16.2408 5.68667 16.8629 6.12667 17.3177 6.74C17.7726 7.34 18 8.14 18 9.14C18 10.1267 17.7726 10.9333 17.3177 11.56C16.8629 12.1733 16.2408 12.62 15.4515 12.9C14.6622 13.1667 13.7525 13.3 12.7224 13.3H9.75251V11.22H12.7224C13.3512 11.22 13.8863 11.1533 14.3278 11.02C14.7692 10.8733 15.1171 10.6467 15.3712 10.34C15.6254 10.0333 15.7525 9.63333 15.7525 9.14C15.7525 8.66 15.6254 8.26667 15.3712 7.96C15.1171 7.65333 14.7692 7.43333 14.3278 7.3C13.8863 7.15333 13.3512 7.08 12.7224 7.08H9.75251ZM10.5151 17.12V15.18H14.6288V17.12H10.5151ZM6 13.24V11.3H10.1137V13.24H6ZM6 17.12V15.18H10.1137V17.12H6Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_2421_129">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const CurrencyIcon: React.FC<{ currency?: string; enabled: boolean; }> = ({ currency, enabled }) => {
  const color = enabled ? 'white' : '#AAAAAA'

  switch (currency?.toUpperCase()) {
    case 'RUB':
      return <RUBIcon color={color} />
    default:
      return null
  }
}

export const TransferButton: React.FC<{
  form: FormInstance
  isLoading?: boolean
  currency?: string
  percentage_fee?: string
}> = ({
  form,
  isLoading,
  currency,
  percentage_fee,
}) => {
    const [submittable, setSubmittable] = useState<boolean>(false);
    const values = Form.useWatch([], form);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);

    const t = useTranslate()

    const amount = form.getFieldValue('amount') ? Number(form.getFieldValue('amount')) : 0
    const fee = percentage_fee ? amount * Number(percentage_fee) / 100 : 0
    const full_amount = (amount + fee).toFixed(2)

    return (
      <Button
        disabled={!submittable}
        htmlType="submit"
        loading={isLoading}

        style={{
          textAlign: 'center',
          height: 56,
          padding: '16px 24px',
          borderRadius: 60,
          background: submittable ? 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)' : '#F5F5F5',
          border: 0,
          boxShadow: 'none',
          margin: '0 auto',
        }}
      >
        <Flex
          justify='space-around'
          align='center'
          gap={10}
        >
          <Text
            style={{
              height: 23,
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '700',
              lineHeight: '23.12px',
              color: submittable ? '#FFFFFF' : '#AAAAAA',
            }}
          >
            <Space>
              {t("pages.withdraw.buttons.transfer")}
              {full_amount}
            </Space>
          </Text>
          {full_amount && <CurrencyIcon currency={currency} enabled={submittable} />}
        </Flex>
      </Button >
    );
  };