import React from 'react'
import { gatewayStatus } from 'interfaces'
import { Tag } from 'antd'

export const GatewayStatus: React.FC<{ status: gatewayStatus }> = ({ status }) => {
  const ColorMap = {
    active: 'success',
    blocked: 'error',
  }

  return (
    <Tag
      color={ColorMap[status]}
    >
      {status}
    </Tag>
  )
}
