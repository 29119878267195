import React from 'react';
import {
  useTranslate,
  useList,
  useApiUrl,
  useCustomMutation
} from '@refinedev/core'
import { Link, useParams } from "react-router-dom";
import { Flex, Typography, Form, Spin, Skeleton, Select } from 'antd'
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

import { Wrapper } from 'components/client_ui/Wrapper'
import { IGateway, ICard, IOperation } from 'interfaces';
import { TransferButton } from './transfer_btn';
import { HomeButton } from 'components/client_ui/HomeButton';
import { ChipAmount } from 'components/client_ui/ChipAmount';
import { ChipPercent } from 'components/client_ui/ChipPercent';
import { ChipBankCard, ChipBankCardNew } from 'components/client_ui/ChipBankCard';
import { BackButton } from './back_btn';
import { LanguageIcon, CheckIcon } from './icons';
import { ChipGateway, ChipFortuneGateway } from 'components/client_ui/ChipGateway';
import { TextJost } from 'components/client_ui/TextJost';
import { luhnValidate, formatAmount, nspkCodes, checkPhone, statusColor } from 'components/client_ui/utils';
import { WalletBalance } from './wallet_balance';
import {
  InputAmount,
  InputCardNumber,
  InputPhoneNumber,
  CurrencyIcon,
} from './inputs';

const { Text } = Typography

const AmountInfo: React.FC<{
  currentState: CurrentState
  currentOperation: IOperation
}> = ({
  currentState,
  currentOperation
}) => {
    const t = useTranslate()

    const amount_to_send = Number(currentOperation.amount)
    const percentage_fee = Number(currentOperation?.tariff?.percentage_fee)
    const fee = amount_to_send * percentage_fee / 100
    const amount_withdraw = amount_to_send + fee

    return (
      <Flex
        vertical
        gap={16}
      >
        <Flex vertical gap={8}>
          <TextJost fontWeight='500'>{t('pages.withdraw.titles.amount_to_send')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={20} fontWeight='800'>{formatAmount(amount_to_send)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
        <Flex vertical gap={4}>
          <TextJost fontWeight='500'>{t('pages.withdraw.titles.amount_withdraw')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={16} fontWeight='700'>{formatAmount(amount_withdraw)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
        <Flex vertical gap={4}>
          <TextJost fontWeight='500'>{t('pages.withdraw.titles.fee')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={16} fontWeight='600'>{formatAmount(fee)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
      </Flex>
    )
  }

interface CurrentState {
  currency: string | undefined
  gateway: IGateway | undefined
  card: ICard | undefined
  amount: string
  cardNumber: string
  phoneNumber: string
  nspkCode: string
  balance: string
}

export const WithdrawShow: React.FC = () => {
  const t = useTranslate()

  const params = useParams()
  const [form] = Form.useForm()

  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<IOperation>()

  const [isLoading, setIsLoading] = useState(false);
  const [currentOperation, setOperation] = useState<IOperation | undefined>(undefined)

  const [currentState, setCurrentState] = useState<CurrentState>({
    currency: params?.currency_code?.toUpperCase(),
    gateway: undefined,
    card: undefined,
    amount: '',
    cardNumber: '',
    phoneNumber: '',
    nspkCode: '',
    balance: '',
  })

  useEffect(() => {
    form.setFieldsValue({
      amount: currentState.amount ?? '',
    })
  }, [currentState.amount])

  useEffect(() => {
    form.setFieldsValue({
      gateway_id: currentState.gateway?.id,
    })
  }, [currentState.gateway]);

  useEffect(() => {
    if (currentState.card) {
      const formated =
        currentState.card?.number?.match(/^\d{16}$/) ?
          currentState.card?.number?.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4') :
          currentState.card?.number

      form.setFieldsValue({
        cardNumber: currentState.card?.number ? formated : '',
      })
    } else {
      form.setFieldsValue({
        cardNumber: '',
      })
    }
  }, [currentState.card])

  const createOperation = () => {
    if (isLoading) return
    setIsLoading(true)

    let values = {
      kind: 'wallet_withdraw',
      gateway_id: currentState.gateway?.id,
      currency_code: currentState.currency,
      amount: currentState.amount,
    }

    if (currentState.gateway?.payment_type === 'card') {
      const number = currentState.cardNumber?.replace(/\D/g, '')

      values = Object.assign({
        payout_requisite: {
          card: {
            number: number,
          },
        }

      }, values)
    }

    if (currentState.gateway?.payment_type === 'sbp') {
      const number = currentState.phoneNumber?.replace(/\D/g, '')

      values = Object.assign({
        payout_requisite: {
          phone: {
            number: number,
            nspk_code: currentState.nspkCode?.replace(/\D/g, '')
          },
        }

      }, values)
    }

    customMutate(
      {
        url: `${apiUrl}/operations/`,
        method: 'post',
        values: values,
      },
      {
        onError: (error) => {
          setIsLoading(false)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setOperation(response?.data)
        },
      }
    )
  }

  const selectGateway = (gateway: IGateway | undefined) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      gateway: prevState.gateway ? undefined : gateway,
      currentCard: undefined,
    }))
  }

  const selectCard = (card: ICard | undefined) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      card: prevState.card ? undefined : card,
      cardNumber: prevState.card ? '' : card?.number ? card?.number : prevState.cardNumber,
    }))
  }

  const handleFormChange = (field: keyof CurrentState) => (value: string) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const changeAmount = (value: string) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      amount: value,
    }))
  }

  const changeBalance = (value: string) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      balance: value,
    }))
  }

  const { data: cardsData } = useList<ICard>({
    resource: 'cards',
    filters: [
      {
        field: "currency_code",
        operator: "eq",
        value: currentState.currency,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 3,
    },
  })
  const cards = cardsData?.data ?? [];

  const listCards = cards.slice(0, 3).map(card =>
    <ChipBankCard
      key={card.id}
      card={card}
      selected_id={currentState.card?.id}
      selectCard={selectCard}
    />
  )

  const { data: gatewaysData, isLoading: isFetchingGateways } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: "currency_code",
        operator: "eq",
        value: currentState.currency,
      },
      {
        field: "direction",
        operator: "eq",
        value: 'outcome',
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? [];

  const listGateways = gateways?.map(method =>
    <ChipGateway
      key={method.id}
      gateway={method}
      selected_id={currentState.gateway?.id}
      selectGateway={selectGateway}
    />
  )

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 32px 10px 32px',
          minHeight: '100vh',
        }}
      >
        {isLoading &&
          <Spin
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 5,
            }}
          />
        }

        {!isLoading && currentOperation &&
          <>
            <Flex
              vertical
              gap={64}
            >
              <Flex
                justify='center'
                align='center'
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: '50%',
                  background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                  boxShadow: '0px 0px 120px 0px #75B3FF',
                  margin: '0 auto',
                }}
              >
                <CheckIcon />
              </Flex>
              <Flex
                vertical
                gap={42}
                style={{
                  margin: '0 auto'
                }}
              >
                <Flex
                  vertical
                  gap={16}
                >
                  <TextJost
                    fontWeight='500'
                    style={{
                      fontSize: 16,
                      color: statusColor(currentOperation.status),
                      margin: '0 auto'
                    }}
                  >
                    {t('components.operations_list.statuses.' + currentOperation.status)}
                  </TextJost>
                  <AmountInfo currentState={currentState} currentOperation={currentOperation} />
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.withdraw.titles.payment_method')}</TextJost>
                    <Flex
                      vertical
                    >
                      <TextJost fontSize={18} fontWeight='800'>
                        {t('pages.withdraw.payment_methods.' + currentState.gateway?.payment_type)}
                      </TextJost>
                      <TextJost fontSize={16} fontWeight='700'>
                        {currentState.gateway?.name}
                      </TextJost>
                    </Flex>
                  </Flex>
                  <Flex vertical gap={8}>
                    <TextJost fontWeight='500'>{t('pages.withdraw.titles.operation_id')}</TextJost>
                    <TextJost fontSize={12} fontWeight='800'>{currentOperation.id}</TextJost>
                  </Flex>
                </Flex>
                <HomeButton />
              </Flex>
            </Flex>
          </>
        }

        {!isLoading && !currentOperation &&
          <Flex
            vertical
            gap={32}
          >
            <Flex
              justify='space-between'
              align='center'
            >
              <BackButton
                currentGateway={currentState.gateway}
                selectGateway={selectGateway}
              />
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: '23.12px',
                  color: '#303030',
                }}
              >
                {t('pages.withdraw.titles.funds_transfer')}
              </Text>
              <Flex
                justify='space-between'
                align='center'
                gap={24}
              >
                <Link
                  to="/home"
                  children={<LanguageIcon />}
                />
              </Flex>
            </Flex>
            {currentState?.currency &&
              <Flex
                vertical
                gap={16}
              >
                <WalletBalance
                  changeBalance={changeBalance}
                  currency_code={currentState?.currency}
                />
                <div
                  style={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: 8,
                  }}
                >
                  <TextJost fontWeight='700' fontSize={16} style={{ textAlign: 'center', }}>{
                    t('pages.withdraw.titles.withdraw')}
                  </TextJost>
                  <ChipPercent changeAmount={changeAmount} balance={currentState?.balance} percent='30' />
                  <ChipPercent changeAmount={changeAmount} balance={currentState?.balance} percent='50' />
                  <ChipPercent changeAmount={changeAmount} balance={currentState?.balance} percent='70' />
                </div>
              </Flex>
            }
            <Form
              layout="vertical"
              form={form}
              onFinish={() => createOperation()}
              requiredMark={false}
              initialValues={{}}
            >
              <Form.Item
                hidden
                name="gateway_id"
                rules={[
                  { required: true, message: '', },
                  { pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/, message: '', }
                ]}
              >
              </Form.Item>
              <Flex
                vertical
                gap={64}
              >
                <Flex
                  vertical
                  gap={32}
                >
                  <Flex
                    vertical
                    gap={20}
                  >
                    <Text
                      style={{
                        height: 17,
                        fontFamily: 'Jost',
                        fontSize: 12,
                        fontWeight: '700',
                        lineHeight: '17.34px',
                        letterSpacing: '0.1em',
                        textAlign: 'start',
                        color: '#303030',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('pages.withdraw.titles.amount')}
                    </Text>
                    <Flex
                      vertical
                      gap={16}
                    >
                      <Form.Item
                        name="amount"
                        rules={[
                          { required: true, message: '', },
                          { pattern: /^\d+(\.\d{1,2})?$/, message: '', }
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputAmount
                          value={currentState.amount}
                          onChange={handleFormChange('amount')}
                          currency={currentState.currency}
                          maxLength={16}
                          placeholder={t('pages.withdraw.placeholders.amount')}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gap: 8,
                        }}
                      >
                        <ChipAmount changeAmount={changeAmount} amount='500' />
                        <ChipAmount changeAmount={changeAmount} amount='1000' />
                        <ChipAmount changeAmount={changeAmount} amount='2000' />
                        <ChipAmount changeAmount={changeAmount} amount='5000' />
                      </div>
                    </Flex>
                  </Flex>
                  <Flex
                    vertical
                    gap={20}
                  >
                    <Text
                      style={{
                        height: 17,
                        fontFamily: 'Jost',
                        fontSize: 12,
                        fontWeight: '700',
                        lineHeight: '17.34px',
                        letterSpacing: '0.1em',
                        textAlign: 'start',
                        color: '#303030',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('pages.withdraw.titles.transfer_destination')}
                    </Text>
                    <Flex
                      vertical
                      gap={10}
                    >
                      <ChipFortuneGateway selected_id={currentState.gateway?.id} />
                      {isFetchingGateways ? (
                        <Skeleton active />
                      ) : (
                        listGateways
                      )}

                      {currentState.gateway?.payment_type === 'card' &&
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 4,
                          }}
                        >
                          <ChipBankCardNew
                            selectCard={selectCard}
                          />
                          {listCards}
                        </div>
                      }
                    </Flex>
                  </Flex>
                  {currentState.gateway?.payment_type === 'card' &&
                    <Flex
                      vertical
                      gap={16}
                      style={{
                        width: '100%',
                        maxWidth: 400,
                        margin: 'auto'
                      }}
                    >
                      <Form.Item
                        name="cardNumber"
                        hasFeedback={!currentState?.card && currentState.cardNumber.length >= 19}
                        rules={[
                          { required: true, message: '', },
                          { validator: luhnValidate, message: '', },
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputCardNumber
                          disabled={currentState.card ? true : false}
                          value={currentState.cardNumber}
                          onChange={handleFormChange('cardNumber')}
                          maxLength={19}
                          placeholder={t('pages.withdraw.placeholders.card_number')}
                        />
                      </Form.Item>
                    </Flex>
                  }
                  {currentState.gateway?.payment_type === 'sbp' &&
                    <Flex
                      vertical
                      gap={16}
                      style={{
                        width: '100%',
                        maxWidth: 400,
                        margin: 'auto'
                      }}
                    >
                      <Form.Item
                        name="nspkCode"
                        rules={[
                          { required: true, message: '', },
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Select
                          showSearch
                          suffixIcon={null}
                          defaultOpen={currentState.nspkCode ? false : true}
                          variant='borderless'
                          size='large'
                          notFoundContent='Не найдено'
                          options={nspkCodes}
                          onChange={handleFormChange('nspkCode')}
                          optionRender={(option) => (
                            <Text
                              style={{
                                fontFamily: 'Jost',
                                fontSize: 16,
                                fontWeight: '400',
                                color: '#303030',
                              }}
                            >
                              {option.data.label}
                            </Text>
                          )}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          labelRender={(value) => (
                            <Text
                              style={{
                                fontFamily: 'Jost',
                                fontSize: 16,
                                fontWeight: '400',
                                color: '#303030',
                              }}
                            >
                              {value.label}
                            </Text>
                          )}
                          style={{
                            height: 45,
                            padding: '2px 16px',
                            borderRadius: 24,
                            border: '1px solid #EEEEEE',
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          { required: true, message: '', },
                          { validator: checkPhone, message: '', },
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputPhoneNumber
                          value={currentState.phoneNumber}
                          onChange={handleFormChange('phoneNumber')}
                          maxLength={19}
                          placeholder={t('pages.withdraw.placeholders.phone_number')}
                        />
                      </Form.Item>
                    </Flex>
                  }
                </Flex>
                {currentState.gateway && !isLoading &&
                  <TransferButton
                    form={form}
                    currency={currentState.currency}
                    percentage_fee={currentState?.gateway?.tariff?.percentage_fee}
                  />
                }
              </Flex>
            </Form>
          </Flex>
        }
      </Wrapper>
    </motion.div >
  )
}
