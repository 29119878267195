import {
  useIsAuthenticated,
} from '@refinedev/core'

import { Flex } from 'antd'
import { motion } from "framer-motion";

import { Wrapper } from 'components/client_ui/Wrapper'
import { LogoBlack } from 'components/client_ui/LogoBlack';
import { TwoFactorForm } from './two_factor';
import { Buttons } from 'pages/signup/buttons';
import { LoginForm } from './login_form';
import { LogoutBtn } from 'pages/signup/logout_btn';
import { Welcome } from 'components/client_ui/Welcome';

export const Login = () => {
  const { data } = useIsAuthenticated()

  const isTwoFactor = data?.redirectTo === '/two_factor' ? true : false;
  const gap = isTwoFactor ? 98 : 64;

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.45,
        ease: 'easeInOut',
      }}
      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Welcome />
      {isTwoFactor && (
        <LogoutBtn
          style={{
            position: 'absolute',
            bottom: 50,
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 1,
          }}
        />
      )}
      <Wrapper
        style={{
          padding: '64px 42px',
        }}
      >
        <Flex
          vertical
          align='center'
          gap={gap}
        >
          <LogoBlack />
          <Flex
            vertical
            align='center'
            gap={80}
          >
            {isTwoFactor ? (
              <TwoFactorForm />
            ) : (<>
              <Buttons isLogin={true} />
              <LoginForm />
            </>
            )}
          </Flex>
        </Flex>
      </Wrapper>
    </motion.div >
  )
}
