import { useEffect, useState } from "react";
import { useTranslate } from "@refinedev/core";
import { Button, Typography, Flex, Space, Statistic } from "antd";

const { Text } = Typography
const { Countdown } = Statistic

export const ResendButton: React.FC<{
  onClick: () => void
  isLoading?: boolean
}> = ({
  onClick,
  isLoading,
}) => {
    const t = useTranslate()

    const [submittable, setSubmittable] = useState<boolean>(false);
    const [display, setDisplay] = useState<boolean>(true);

    const deadline = Date.now() + 1000 * 60;

    const onPress = () => {
      setDisplay(false);
      onClick();
    }

    return (
      <Button
        onClick={onPress}
        disabled={!submittable}
        loading={isLoading}
        style={{
          textAlign: 'center',
          height: 56,
          padding: '16px 24px',
          borderRadius: 60,
          background: '#F5F5F5',
          border: 0,
          boxShadow: 'none',
          margin: '0 auto',
          display: display ? 'inline-flex' : 'none',
        }}
      >
        <Flex
          justify='space-around'
          align='center'
          gap={10}
        >
          <Space>
            <Text
              style={{
                height: 23,
                fontFamily: 'Jost',
                fontSize: 16,
                fontWeight: '700',
                lineHeight: '23.12px',
                color: '#AAAAAA',
              }}
            >
              {t("pages.deposit.buttons.resend_otp")}
            </Text>
            {!submittable && <Countdown
              format="mm:ss"
              value={deadline}
              onFinish={() => setSubmittable(true)}
              valueStyle={{
                height: 23,
                fontFamily: 'Jost',
                fontSize: 16,
                fontWeight: '700',
                color: '#AAAAAA',
              }}
            />}
          </Space>

        </Flex>
      </Button >
    );
  };