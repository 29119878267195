import React from 'react';
import {
  useTranslate,
  useList,
  useApiUrl,
  useCustomMutation
} from '@refinedev/core'
import { Link, useParams } from "react-router-dom";
import { Flex, Typography, Form, Spin, QRCode, Checkbox, Skeleton } from 'antd'
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

import { Wrapper } from 'components/client_ui/Wrapper'
import { IGateway, ICard, IOperation } from 'interfaces';
import { PayButton } from './pay_btn';
import { HomeButton } from 'components/client_ui/HomeButton';
import { ResendButton } from './resend_btn';
import { ChipAmount } from 'components/client_ui/ChipAmount';
import { ChipBankCard, ChipBankCardNew } from 'components/client_ui/ChipBankCard';
import { BackButton } from './back_btn';
import { LanguageIcon, TouchIcon, TimeIcon, CheckIcon } from './icons';
import { ChipGateway } from 'components/client_ui/ChipGateway';
import { TextJost } from 'components/client_ui/TextJost';
import { luhnValidate, formatAmount } from 'components/client_ui/utils';
import {
  InputAmount,
  InputCardNumber,
  InputCardDate,
  InputCardCVV,
  InputCardHolderName,
  InputEcomOTP,
  CurrencyIcon,
} from './inputs';

const { Text } = Typography

const AmountInfo: React.FC<{
  currentState: CurrentState
  currentOperation: IOperation
}> = ({
  currentState,
  currentOperation
}) => {
    const t = useTranslate()

    const amount_to_pay = Number(currentOperation.amount)
    const percentage_fee = Number(currentOperation?.tariff?.percentage_fee)
    const fee = amount_to_pay - amount_to_pay / (percentage_fee + 100) * 100
    const amount_deposit = amount_to_pay - fee

    return (
      <Flex
        vertical
        gap={16}
      >
        <Flex vertical gap={8}>
          <TextJost fontWeight='500'>{t('pages.deposit.titles.amount_to_pay')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={20} fontWeight='800'>{formatAmount(amount_to_pay)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
        <Flex vertical gap={4}>
          <TextJost fontWeight='500'>{t('pages.deposit.titles.amount_deposit')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={16} fontWeight='700'>{formatAmount(amount_deposit)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
        <Flex vertical gap={4}>
          <TextJost fontWeight='500'>{t('pages.deposit.titles.fee')}</TextJost>
          <Flex
            align='center'
          >
            <TextJost fontSize={16} fontWeight='600'>{formatAmount(fee)}</TextJost>
            <CurrencyIcon currency={currentState.currency} />
          </Flex>
        </Flex>
      </Flex>
    )
  }

interface CurrentState {
  currency: string | undefined
  gateway: IGateway | undefined
  card: ICard | undefined
  amount: string
  saveCard: boolean
  cardNumber: string
  cardDate: string
  cardCVV: string
  cardHolderName: string
  ecomOTP: string
}

export const DepositShow: React.FC = () => {
  const t = useTranslate()

  const params = useParams()
  const [form] = Form.useForm()

  const apiUrl = useApiUrl()
  const { mutate: customMutate } = useCustomMutation<IOperation>()

  const [isLoading, setIsLoading] = useState(false);
  const [currentOperation, setOperation] = useState<IOperation | undefined>(undefined)

  const [currentState, setCurrentState] = useState<CurrentState>({
    currency: params?.currency_code?.toUpperCase(),
    gateway: undefined,
    card: undefined,
    amount: '',
    saveCard: false,
    cardNumber: '',
    cardDate: '',
    cardCVV: '',
    cardHolderName: '',
    ecomOTP: '',
  })

  useEffect(() => {
    form.setFieldsValue({
      amount: currentState.amount ?? '',
    })
  }, [currentState.amount])

  useEffect(() => {
    form.setFieldsValue({
      gateway_id: currentState.gateway?.id,
    })
  }, [currentState.gateway]);

  useEffect(() => {
    if (currentState.card) {
      const formated =
        currentState.card?.number?.match(/^\d{16}$/) ?
          currentState.card?.number?.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4') :
          currentState.card?.number

      const month = currentState.card?.month?.padStart(2, '0')
      const year = currentState.card?.year?.padStart(2, '0')
      const card_date = month && year ? month + '/' + year : ''

      form.setFieldsValue({
        cardNumber: currentState.card?.number ? formated : '',
        cardDate: card_date,
        cardCVV: '',
        cardHolderName: currentState.card?.holder_name ?? '',
      })
    } else {
      form.setFieldsValue({
        cardNumber: '',
        cardDate: '',
        cardCVV: '',
        cardHolderName: '',
      })
    }
  }, [currentState.card])

  const createOperation = () => {
    if (isLoading) return
    setIsLoading(true)

    const amount_deposit = Number(currentState.amount)
    const percentage_fee = Number(currentState?.gateway?.tariff?.percentage_fee)
    const fee = amount_deposit * percentage_fee / 100
    const amount_to_pay = amount_deposit + fee

    let values = {
      kind: 'wallet_deposit',
      gateway_id: currentState.gateway?.id,
      currency_code: currentState.currency,
      amount: amount_to_pay.toFixed(2),
    }

    if (currentState.gateway?.payment_type === 'ecom') {
      const number = currentState.cardNumber?.replace(/\D/g, '')
      const card_date = currentState.cardDate?.replace(/\D/g, '')
      const month = card_date?.replace(/^(\d{2})(\d{2})$/, '$1')
      const year = card_date?.replace(/^(\d{2})(\d{2})$/, '$2')

      values = Object.assign({
        card: {
          number: number,
          month: month,
          year: year,
          cvv: currentState.cardCVV,
          holder_name: currentState.cardHolderName,
        },
        ...(!currentState.card && { save_card: currentState.saveCard, })
      }, values)
    }

    customMutate(
      {
        url: `${apiUrl}/operations/`,
        method: 'post',
        values: values,
      },
      {
        onError: (error) => {
          setIsLoading(false)
          // console.log(error.statusCode, error.message)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setOperation(response?.data)
        },
      }
    )
  }

  const confirmEcomOTP = () => {
    if (isLoading || !currentOperation?.id) return
    setIsLoading(true)

    customMutate(
      {
        url: `${apiUrl}/operations/${currentOperation?.id}/confirm_otp`,
        method: 'post',
        values: {
          code: currentState.ecomOTP,
        },
      },
      {
        onError: (error) => {
          setIsLoading(false)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setOperation(response?.data)
        },
      }
    )
  }

  const resendEcomOTP = () => {
    if (isLoading || !currentOperation?.id) return
    setIsLoading(true)

    customMutate(
      {
        url: `${apiUrl}/operations/${currentOperation?.id}/resend_otp`,
        method: 'post',
        values: {},
      },
      {
        onError: (error) => {
          setIsLoading(false)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setOperation(response?.data)
        },
      }
    )
  }

  const markAsPaid = () => {
    if (isLoading || !currentOperation?.id) return
    setIsLoading(true)

    customMutate(
      {
        url: `${apiUrl}/operations/${currentOperation?.id}/mark_as_paid`,
        method: 'post',
        values: {},
      },
      {
        onError: (error) => {
          setIsLoading(false)
        },
        onSuccess: (response) => {
          setIsLoading(false)
          setOperation(response?.data)
        },
      }
    )
  }

  const selectGateway = (gateway: IGateway | undefined) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      gateway: prevState.gateway ? undefined : gateway,
      currentCard: undefined,
    }))
  }

  const selectCard = (card: ICard | undefined) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      card: prevState.card ? undefined : card,
      cardNumber: prevState.card ? '' : card?.number ? card?.number : prevState.cardNumber,
      cardDate: prevState.card ? '' : card?.month && card?.year ? card?.month?.padStart(2, '0') + '/' + card?.year : prevState.cardDate,
      cardHolderName: prevState.card ? '' : card?.holder_name ? card?.holder_name : prevState.cardHolderName,
      cardCVV: '',
    }))
  }

  const handleFormChange = (field: keyof CurrentState) => (value: string) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const changeAmount = (value: string) => {
    !isLoading && setCurrentState((prevState) => ({
      ...prevState,
      amount: value,
    }))
  }

  const { data: cardsData } = useList<ICard>({
    resource: 'cards',
    filters: [
      {
        field: "currency_code",
        operator: "eq",
        value: currentState.currency,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 3,
    },
  })
  const cards = cardsData?.data ?? [];

  const listCards = cards.slice(0, 3).map(card =>
    <ChipBankCard
      key={card.id}
      card={card}
      selected_id={currentState.card?.id}
      selectCard={selectCard}
    />
  )

  const { data: gatewaysData, isLoading: isFetchingGateways } = useList<IGateway>({
    resource: 'gateways',
    filters: [
      {
        field: "currency_code",
        operator: "eq",
        value: currentState.currency,
      },
      {
        field: "direction",
        operator: "eq",
        value: 'income',
      },
    ],
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const gateways = gatewaysData?.data ?? [];

  const listGateways = gateways?.map(method =>
    <ChipGateway
      key={method.id}
      gateway={method}
      selected_id={currentState.gateway?.id}
      selectGateway={selectGateway}
    />
  )

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 32px 10px 32px',
          minHeight: '100vh',
        }}
      >
        {isLoading &&
          <Spin
            size="large"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 5,
            }}
          />
        }

        {!isLoading && currentOperation &&
          <>
            {currentState.gateway?.payment_type === 'ecom' &&
              <>
                {currentOperation?.payment_requisite?.ecom?.code_required === true &&
                  <Flex
                    vertical
                    gap={44}
                    style={{
                      maxWidth: 300,
                      margin: 'auto'
                    }}
                  >
                    <BackButton
                      currentGateway={undefined}
                      selectGateway={selectGateway}
                    />
                    <AmountInfo currentState={currentState} currentOperation={currentOperation} />
                    <Flex vertical gap={8}>
                      <TextJost fontWeight='500'>{t('pages.deposit.titles.payment_method')}</TextJost>
                      <Flex
                        vertical
                      >
                        <TextJost fontSize={18} fontWeight='800'>
                          {t('pages.deposit.payment_methods.' + currentState.gateway?.payment_type)}
                        </TextJost>
                        <TextJost fontSize={16} fontWeight='700'>
                          {currentState.gateway?.name}
                        </TextJost>
                      </Flex>
                    </Flex>
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={() => confirmEcomOTP()}
                      requiredMark={false}
                      initialValues={{}}
                    >
                      <Flex
                        vertical
                        gap={24}
                      >
                        <Form.Item
                          name="ecomOTP"
                          rules={[
                            { required: true, message: '', },
                            { pattern: /^\d{4,6}$/, message: '', }
                          ]}
                        >
                          <InputEcomOTP
                            value={currentState.ecomOTP}
                            onChange={handleFormChange('ecomOTP')}
                            maxLength={6}
                            placeholder={t('pages.deposit.placeholders.ecom_otp')}
                          />
                        </Form.Item>
                        {!isLoading &&
                          <PayButton form={form} currency={currentState.currency} />
                        }
                        <ResendButton onClick={resendEcomOTP} />
                      </Flex>
                    </Form>
                  </Flex>
                }
                {currentOperation?.payment_requisite?.ecom?.code_required !== true &&
                  <Flex vertical
                    gap={64}>
                    <Flex
                      justify='center'
                      align='center'
                      style={{
                        height: 120,
                        width: 120,
                        borderRadius: '50%',
                        background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                        boxShadow: '0px 0px 120px 0px #75B3FF',
                        margin: '0 auto',
                      }}
                    >
                      <CheckIcon />
                    </Flex>
                    <Flex
                      vertical
                      gap={42}
                      style={{
                        margin: '0 auto'
                      }}
                    >
                      <Flex
                        vertical
                        gap={16}
                      >
                        <AmountInfo currentState={currentState} currentOperation={currentOperation} />
                        <Flex vertical gap={8}>
                          <TextJost fontWeight='500'>{t('pages.deposit.titles.payment_method')}</TextJost>
                          <Flex
                            vertical
                          >
                            <TextJost fontSize={18} fontWeight='800'>
                              {t('pages.deposit.payment_methods.' + currentState.gateway?.payment_type)}
                            </TextJost>
                            <TextJost fontSize={16} fontWeight='700'>
                              {currentState.gateway?.name}
                            </TextJost>
                          </Flex>
                        </Flex>
                        <Flex vertical gap={8}>
                          <TextJost fontWeight='500'>{t('pages.deposit.titles.operation_id')}</TextJost>
                          <TextJost fontSize={12} fontWeight='800'>{currentOperation.id}</TextJost>
                        </Flex>
                      </Flex>
                      <HomeButton />
                    </Flex>
                  </Flex>
                }
              </>
            }

            {currentState.gateway?.payment_type !== 'ecom' &&
              <Flex
                vertical
                gap={64}
              >
                <Flex
                  justify='center'
                  align='center'
                  style={{
                    height: 120,
                    width: 120,
                    borderRadius: '50%',
                    background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                    boxShadow: '0px 0px 120px 0px #75B3FF',
                    margin: '0 auto',
                  }}
                >
                  <TouchIcon />
                </Flex>
                <Flex
                  vertical
                  gap={42}
                  style={{
                    margin: '0 auto'
                  }}
                >
                  <Flex
                    vertical
                    gap={16}
                  >
                    {currentState.gateway?.payment_type === 'sbpqr' &&
                      <Link
                        to={currentOperation?.payment_requisite?.sbpqr?.page ?? ''}
                        target='_blank'
                      >
                        <Flex
                          vertical
                          gap={8}
                        >
                          <TextJost fontWeight='500' style={{ textAlign: 'center' }}>{t('pages.deposit.titles.scan_qr')}</TextJost>
                          <QRCode
                            size={250}
                            errorLevel="Q"
                            value={currentOperation?.payment_requisite?.sbpqr?.page ?? ''}
                          />
                        </Flex>
                      </Link>
                    }
                    {currentState.gateway?.payment_type === 'card' &&
                      <Flex vertical gap={8}>
                        <TextJost fontWeight='500'>{t('pages.deposit.titles.requisite')}</TextJost>
                        <Flex vertical>
                          <TextJost fontSize={20} fontWeight='800' copyable>
                            {currentOperation?.payment_requisite?.card?.number}
                          </TextJost>
                          <TextJost fontSize={16} fontWeight='700'>
                            {currentOperation?.payment_requisite?.card?.cardholder_name}
                          </TextJost>
                        </Flex>
                      </Flex>
                    }
                    {currentState.gateway?.payment_type === 'sbp' &&
                      <Flex vertical gap={8}>
                        <TextJost fontWeight='500'>{t('pages.deposit.titles.requisite')}</TextJost>
                        <Flex
                          vertical
                        >
                          <TextJost fontSize={20} fontWeight='800' copyable>
                            {currentOperation?.payment_requisite?.sbp?.phone}
                          </TextJost>
                          <TextJost fontSize={16} fontWeight='700'>
                            {currentOperation?.payment_requisite?.sbp?.bank_name}
                          </TextJost>
                          <TextJost fontSize={16} fontWeight='700'>
                            {currentOperation?.payment_requisite?.sbp?.holder_name}
                          </TextJost>
                        </Flex>
                      </Flex>
                    }
                    {currentState.gateway?.payment_type === 'account' &&
                      <Flex vertical gap={8}>
                        <TextJost fontWeight='500'>{t('pages.deposit.titles.requisite')}</TextJost>
                        <TextJost fontSize={20} fontWeight='800' copyable>
                          {currentOperation?.payment_requisite?.account?.number}
                        </TextJost>
                        <TextJost fontSize={16} fontWeight='700' copyable>
                          {currentOperation?.payment_requisite?.account?.bic}
                        </TextJost>
                        <TextJost fontSize={16} fontWeight='700'>
                          {currentOperation?.payment_requisite?.account?.full_name}
                        </TextJost>
                      </Flex>
                    }
                    <AmountInfo currentState={currentState} currentOperation={currentOperation} />
                    <Flex vertical gap={8}>
                      <TextJost fontWeight='500'>{t('pages.deposit.titles.payment_method')}</TextJost>
                      <Flex
                        vertical
                      >
                        <TextJost fontSize={18} fontWeight='800'>
                          {t('pages.deposit.payment_methods.' + currentState.gateway?.payment_type)}
                        </TextJost>
                        <TextJost fontSize={16} fontWeight='700'>
                          {currentState.gateway?.name}
                        </TextJost>
                      </Flex>
                    </Flex>
                    <Flex vertical gap={8}>
                      <TextJost fontWeight='500'>{t('pages.deposit.titles.operation_id')}</TextJost>
                      <TextJost fontSize={12} fontWeight='800'>{currentOperation.id}</TextJost>
                    </Flex>
                  </Flex>
                  <HomeButton />
                </Flex>
              </Flex>}
          </>
        }

        {!isLoading && !currentOperation &&
          <Flex
            vertical
            gap={32}
          >
            <Flex
              justify='space-between'
              align='center'
            >
              <BackButton
                currentGateway={currentState.gateway}
                selectGateway={selectGateway}
              />
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: '23.12px',
                  color: '#303030',
                }}
              >
                {t('pages.deposit.titles.deposit')}
              </Text>
              <Flex
                justify='space-between'
                align='center'
                gap={24}
              >
                <Link
                  to="/home"
                  children={<LanguageIcon />}
                />
              </Flex>
            </Flex>
            <Form
              layout="vertical"
              form={form}
              onFinish={() => createOperation()}
              requiredMark={false}
              initialValues={{}}
            >
              <Form.Item
                hidden
                name="gateway_id"
                rules={[
                  { required: true, message: '', },
                  { pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/, message: '', }
                ]}
              >
              </Form.Item>
              <Flex
                vertical
                gap={64}
              >
                <Flex
                  vertical
                  gap={32}
                >
                  <Flex
                    vertical
                    gap={20}
                  >
                    <Text
                      style={{
                        height: 17,
                        fontFamily: 'Jost',
                        fontSize: 12,
                        fontWeight: '700',
                        lineHeight: '17.34px',
                        letterSpacing: '0.1em',
                        textAlign: 'start',
                        color: '#303030',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('pages.deposit.titles.amount')}
                    </Text>
                    <Flex
                      vertical
                      gap={16}
                    >
                      <Form.Item
                        name="amount"
                        rules={[
                          { required: true, message: '', },
                          { pattern: /^\d+(\.\d{1,2})?$/, message: '', }
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputAmount
                          value={currentState.amount}
                          onChange={handleFormChange('amount')}
                          currency={currentState.currency}
                          maxLength={16}
                          placeholder={t('pages.deposit.placeholders.amount')}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(4, 1fr)',
                          gap: 8,
                        }}
                      >
                        <ChipAmount changeAmount={changeAmount} amount='500' />
                        <ChipAmount changeAmount={changeAmount} amount='1000' />
                        <ChipAmount changeAmount={changeAmount} amount='2000' />
                        <ChipAmount changeAmount={changeAmount} amount='5000' />
                      </div>
                    </Flex>
                  </Flex>
                  <Flex
                    vertical
                    gap={20}
                  >
                    <Text
                      style={{
                        height: 17,
                        fontFamily: 'Jost',
                        fontSize: 12,
                        fontWeight: '700',
                        lineHeight: '17.34px',
                        letterSpacing: '0.1em',
                        textAlign: 'start',
                        color: '#303030',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('pages.deposit.titles.payment_method')}
                    </Text>
                    <Flex
                      vertical
                      gap={10}
                    >
                      {isFetchingGateways ? (
                        <Skeleton active />
                      ) : (
                        listGateways
                      )}
                      {currentState.gateway?.payment_type === 'ecom' &&
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 4,
                          }}
                        >
                          <ChipBankCardNew
                            selectCard={selectCard}
                          />
                          {listCards}
                        </div>
                      }
                    </Flex>
                  </Flex>
                  {currentState.gateway?.payment_type === 'ecom' &&
                    <Flex
                      vertical
                      gap={16}
                      style={{
                        maxWidth: 400,
                        margin: 'auto'
                      }}
                    >
                      <Form.Item
                        name="cardNumber"
                        hasFeedback={!currentState?.card && currentState.cardNumber.length >= 19}
                        rules={[
                          { required: true, message: '', },
                          { validator: luhnValidate, message: '', },
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputCardNumber
                          disabled={currentState.card ? true : false}
                          value={currentState.cardNumber}
                          onChange={handleFormChange('cardNumber')}
                          maxLength={19}
                          placeholder={t('pages.deposit.placeholders.card_number')}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gap: 16,
                        }}
                      >
                        <Form.Item
                          name="cardDate"
                          hasFeedback={!currentState?.card && currentState.cardDate.length === 5}
                          rules={[
                            { required: true, message: '', },
                            { pattern: /^\d{2}[/]\d{2}$/, message: '', }
                          ]}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <InputCardDate
                            disabled={currentState.card ? true : false}
                            value={currentState.cardDate}
                            onChange={handleFormChange('cardDate')}
                            maxLength={5}
                            placeholder={t('pages.deposit.placeholders.card_date')}
                          />
                        </Form.Item>
                        <Form.Item
                          name="cardCVV"
                          hasFeedback={currentState.cardCVV.length === 3}
                          rules={[
                            { required: true, message: '', },
                            { pattern: /^\d{3}$/, message: '', }
                          ]}
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <InputCardCVV
                            value={currentState.cardCVV}
                            onChange={handleFormChange('cardCVV')}
                            maxLength={3}
                            placeholder={t('pages.deposit.placeholders.card_cvv')}
                          />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name="cardHolderName"
                        hasFeedback={!currentState?.card && currentState.cardHolderName.length > 2}
                        rules={[
                          { required: true, message: '', },
                          { pattern: /^[a-zA-Z]+\s[a-zA-Z]+$/, message: '', }
                        ]}
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <InputCardHolderName
                          disabled={currentState.card ? true : false}
                          value={currentState.cardHolderName}
                          onChange={handleFormChange('cardHolderName')}
                          maxLength={32}
                          placeholder={t('pages.deposit.placeholders.card_holder_name')}
                        />
                      </Form.Item>
                      {!currentState.card && <Checkbox
                        checked={currentState.saveCard}
                        onChange={(e) => {
                          setCurrentState((prevState) => ({
                            ...prevState,
                            saveCard: e.target.checked,
                          }))
                        }}
                        style={{
                          color: '#303030',
                        }}
                      >
                        {t('pages.deposit.titles.save_card')}
                      </Checkbox>}
                    </Flex>
                  }
                </Flex>
                {currentState.gateway && !isLoading &&
                  <PayButton
                    form={form}
                    currency={currentState.currency}
                    percentage_fee={currentState?.gateway?.tariff?.percentage_fee}
                  />
                }
              </Flex>
            </Form>
          </Flex>
        }
      </Wrapper>
    </motion.div >
  )
}
