import { Link } from 'react-router-dom'
import { useTranslate } from '@refinedev/core'
import { Typography } from 'antd'

const { Text } = Typography

const Icon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_118_6259)">
        <path d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_118_6259">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export const Switch: React.FC = () => {
  const t = useTranslate()

  return (
    <span
      style={{
        display: 'flex',
        width: 163,
        height: 48,
        borderRadius: '30px',
        background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
        boxShadow: '0px 0px 10px 0px #0000001A',
        alignItems: 'center',
      }}
    >
      <Link
        to="/home"
        style={{
          display: 'inline-block',
          width: 48,
          height: 48,
          padding: 12,
          borderRadius: 50,
          background: '#FFFFFF',
        }}
        children={<Icon />}
      />
      <span
        style={{
          display: 'inline-block',
          height: 20,
          paddingLeft: 23,
          width: 115,
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 14,
            fontWeight: '700',
            lineHeight: '20.23px',
            letterSpacing: '0.1em',
            textAlign: 'center',
            color: '#FFFFFF',
            textTransform: 'uppercase',
          }}
        >
          {t('components.top_menu.titles.crypto')}
        </Text>
      </span>
    </span>
  )
}