import { Flex } from 'antd'
import { motion } from "framer-motion";

import { LogoWhite } from 'components/client_ui/LogoWhite';
import { HIDE_WELCOME_KEY } from '../../../constants';

const Elipse: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  return (
    <motion.div
      initial={{
        scale: 1,
      }}
      animate={{
        scale: 15,
      }}
      transition={{
        duration: 1.2,
        ease: [0.7, -0.4, 0.4, 1.4],
        delay: 0.1,
      }}
      style={Object.assign({
        height: 140,
        width: 140,
        background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
        borderRadius: '50%',
      }, style)}
    />
  )
}

export const Welcome: React.FC = () => {
  if (localStorage.getItem(HIDE_WELCOME_KEY) === 'required') {
    return null
  }

  setTimeout(() => {
    localStorage.setItem(HIDE_WELCOME_KEY, 'required')
  }, 2100)
  
  return (
    <motion.div
      animate={{
        opacity: [1, 0],
        zIndex: 0,
      }}
      transition={{
        duration: .15,
        ease: 'easeInOut',
        delay: 1.7,
      }}
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        background: '#FFFFFF',
        zIndex: 1,
      }}
    >
      <Flex
        justify='center'
        align='center'
        style={{
          height: '100vh',
        }}
      >
        <Elipse
          style={{
            position: 'absolute',
            top: -70,
            left: -70,
            zIndex: 2,
          }}
        />

        <motion.div
          initial={{
            opacity: 0,
            scale: 0,
          }}
          animate={{
            opacity: 1,
            scale: 20,
          }}
          transition={{
            duration: 1.2,
            ease: [0.7, -0.4, 0.4, 1.4],
            delay: 0.1,
          }}
          style={{
            height: 140,
            width: 140,
            background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
            borderRadius: '50%',
            zIndex: 3,
          }}
        />

        <motion.div
          initial={{
            opacity: 0,
            scale: 0,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 0.35,
            ease: [0.7, -0.4, 0.4, 1.4],
            delay: 0.8,
          }}
          style={{
            position: 'absolute',
            zIndex: 4,
          }}
        >
          <LogoWhite />
        </motion.div>

        <Elipse
          style={{
            position: 'absolute',
            bottom: -70,
            right: -70,
            zIndex: 2,
          }}
        />
      </Flex>
    </motion.div>
  )
}