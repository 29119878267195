import React from 'react'
import { gatewayTypes } from 'interfaces'
import { Tag } from 'antd'

export const GatewayType: React.FC<{ type: gatewayTypes }> = ({ type }) => {
  const ColorMap = {
    card: 'blue',
    sbp: 'green',
    ecom: 'orange',
    account: 'purple',
    sbpqr: 'geekblue'
  }

  return (
    <Tag
      color={ColorMap[type]}
    >
      {type}
    </Tag>
  )
}
