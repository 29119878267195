import { useTranslate } from "@refinedev/core"
import { Flex, Typography } from "antd"
import { Link } from "react-router-dom"

const { Text } = Typography

export const Buttons: React.FC<{ isLogin: boolean }> = ({ isLogin }) => {
  const t = useTranslate()

  return (
    <Flex
      wrap
      gap={8}
    >
      <Link
        to='/signup'
        style={{
          height: 55,
          padding: '16px 24px',
          borderRadius: '60px',
          border: isLogin ? 'none' : '1px solid #303030',
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '700',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {t('pages.signup.buttons.signup')}
        </Text>
      </Link>
      <Link
        to='/login'
        style={{
          height: 55,
          padding: '16px 24px',
          borderRadius: '60px',
          border: isLogin ? '1px solid #303030' : 'none',
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '700',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {t('pages.signup.buttons.login')}
        </Text>
      </Link>
    </Flex>
  )
}