import { Typography } from "antd/lib"

const { Text } = Typography

export const ChipAmount: React.FC<{
  changeAmount: (value: string) => void
  amount: string
}> = ({
  changeAmount,
  amount
}) => {
    return (
      <span
        onClick={() => changeAmount(amount)}
        style={{
          height: 39,
          padding: 8,
          borderRadius: 10,
          border: '1px solid #303030',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Text
          style={{
            height: 23,
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '700',
            lineHeight: '23.12px',
            textAlign: 'center',
            color: '#303030',
          }}
        >
          {amount}
        </Text>
      </span>
    )
  }