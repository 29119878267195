import React from 'react'
import { gatewayDirection } from 'interfaces'
import { Tag } from 'antd'

export const GatewayDirection: React.FC<{ direction: gatewayDirection }> = ({ direction }) => {
  const ColorMap = {
    income: 'green',
    outcome: 'red',
  }

  return (
    <Tag
      color={ColorMap[direction]}
    >
      {direction}
    </Tag>
  )
}
