import React, { ReactNode } from "react";
import { motion } from "framer-motion";

export const Wrapper: React.FC<{
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  children,
  style = {}
}) => {
    return (
      <motion.div
        // animate={{
        //   scale: [0.5, 1]
        // }}
        // transition={{
        //   type: "spring", stiffness: 400, damping: 25,
        //   duration: 0.2,

        //   "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate"
        //   easings: ["easeIn", "easeOut"],
        //   ease: 'easeInOut',
        // }}

        children={children}
        style={Object.assign({
          position: 'relative',
          minHeight: 360,
          maxWidth: 800,
          margin: 'auto',
        }, style)}
      />
    )
  }