import {
  Authenticated,
  CanAccess,
  HttpError,
  Refine,
} from '@refinedev/core'

import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar'
import { useNotificationProvider, ThemedTitleV2, RefineThemes } from '@refinedev/antd'
import '@refinedev/antd/dist/reset.css'
import { ConfigProvider } from "antd";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6'

import { useTranslation } from 'react-i18next'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'

import {
  HomeOutlined,
  DashboardOutlined,
  ShopOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  ProfileOutlined,
  ApiOutlined,
  BankOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  MoneyCollectOutlined,
  ToolOutlined,
  ProjectOutlined
} from '@ant-design/icons'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { TOKEN_KEY, BACKEND_URL } from './constants'
import { authProvider } from './authProvider'
import { fortuneDataProvider } from 'dataProvider'
import { accessControlProvider } from 'accessControlProvider'

import { CustomThemedLayoutV2 } from "components/CustomThemedLayoutV2"
import { AppIcon } from 'components/app-icon'
import { AdminWrapper } from 'components/AdminWrapper'
import { BgWrapper } from 'components/BgWrapper';

import { Login } from 'pages/login'
import { Signup } from 'pages/signup'
import { HomeShow } from 'pages/home'
import { ProfileShow } from 'pages/profile'
import { AboutusShow } from 'pages/aboutus'
import { Invoices } from 'pages/invoices/list';
import { Operations, OperationShow } from 'pages/operations';

import { DashboardShow } from 'pages/dashboard'
import { MerchantCreate, MerchantEdit, MerchantList, MerchantShow } from 'pages/merchants'
import { UserList, UserShow, UserEdit } from 'pages/users'
import { AdminAccountList } from 'pages/admin/accounts'
import { AdminTariffsList, AdminTariffCreate } from 'pages/admin/tariffs';
import { AdminPaymentLimitsList, AdminPaymentLimitCreate } from 'pages/admin/payment_limits';
import { AdminOperationsList } from 'pages/admin/operations';
import { AdminInvoicesList } from 'pages/admin/invoices';
import { AdminPaymentProvidersList } from 'pages/admin/payment_providers';
import { AdminCardsList } from 'pages/admin/cards';
import { AdminEventsList } from 'pages/admin/events';
import { ApiTokens } from 'pages/api_tokens';
import { GatewaysList } from 'pages/gateways';
import { DepositShow } from 'pages/deposit';
import { WithdrawShow } from 'pages/withdraw';

import './styles.css'

require('dayjs/locale/ru')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ru')

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    if (request.headers) {
      request.headers['Authorization'] = `Bearer ${token}`
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.errors?.join(', '),
      statusCode: error.response?.status,
    }

    return Promise.reject(customError)
  }
)

const CustomTheme = {
  token: {
    fontFamily: 'Jost',
    colorPrimary: RefineThemes.Blue.token?.colorPrimary,
  },
  components: {}
}

function App() {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ConfigProvider
          theme={CustomTheme}
        >
          <Refine
            dataProvider={fortuneDataProvider(BACKEND_URL, axiosInstance)}
            notificationProvider={useNotificationProvider}
            authProvider={authProvider(axiosInstance)}
            i18nProvider={i18nProvider}
            routerProvider={routerBindings}
            accessControlProvider={accessControlProvider}
            resources={[
              {
                name: 'home',
                list: '/home',
                meta: { icon: <HomeOutlined />, },
              },
              {
                name: 'profile',
                list: '/profile',
                meta: { icon: <ProfileOutlined /> },
              },
              {
                name: 'aboutus',
                list: '/aboutus',
                meta: { hide: true, },
              },
              {
                name: 'invoices',
                list: '/invoices',
                meta: { hide: true, },
              },
              {
                name: 'operations',
                list: '/operations',
                show: '/operations/show/:id',
                meta: { hide: true, },
              },
              {
                name: 'deposit',
                list: '/deposit/:currency_code',
                meta: { hide: true, },
              },
              {
                name: 'withdraw',
                list: '/withdraw/:currency_code',
                meta: { hide: true, },
              },
              {
                name: 'dashboard',
                list: '/dashboard',
                meta: { icon: <DashboardOutlined />, },
              },
              {
                name: 'admin_events',
                list: '/admin/events',
                meta: { icon: <HistoryOutlined />, },
              },
              {
                name: 'merchants',
                list: '/merchants',
                edit: '/merchants/edit/:id',
                show: '/merchants/show/:id',
                create: '/merchants/create',
                meta: { icon: <ShopOutlined />, },
              },
              {
                name: 'users',
                list: '/users',
                show: '/users/show/:id',
                edit: '/users/edit/:id',
                meta: { icon: <TeamOutlined />, },
              },
              {
                name: 'gateways',
                list: '/gateways',
                meta: { icon: <BankOutlined />, },
              },
              {
                name: 'admin_accounts',
                list: '/admin/accounts',
                meta: { icon: <DollarCircleOutlined />, },
              },
              {
                name: 'admin_account_entries',
                list: '/admin/account_entries',
                meta: { hide: true, },
              },
              {
                name: 'admin_invoices',
                list: '/admin/invoices',
                meta: { icon: <OrderedListOutlined />, },
              },
              {
                name: 'admin_operations',
                list: '/admin/operations',
                meta: { icon: <UnorderedListOutlined />, },
              },
              {
                name: 'admin/tariffs',
                list: '/admin/tariffs',
                create: '/admin/tariffs/create',
                meta: { icon: <MoneyCollectOutlined />, },
              },
              {
                name: 'admin/payment_limits',
                list: '/admin/payment_limits',
                create: '/admin/payment_limits/create',
                meta: { icon: <ToolOutlined />, },
              },
              {
                name: 'admin_cards',
                list: '/admin/cards',
                meta: { icon: <CreditCardOutlined />, },
              },
              {
                name: 'admin_payment_providers',
                list: '/admin/payment_providers',
                meta: { icon: <ProjectOutlined />, },
              },
              {
                name: 'api_tokens',
                list: '/api_tokens',
                meta: { icon: <ApiOutlined />, },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              disableTelemetry: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <CustomThemedLayoutV2 Title={({ collapsed }) => (
                      <ThemedTitleV2 collapsed={collapsed} text={null} icon={<AppIcon />} />
                    )}>
                      <CanAccess>
                        <Outlet />
                      </CanAccess>
                    </CustomThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<NavigateToResource resource="home" />} />

                {/* client */}
                <Route path="/home">
                  <Route index element={<BgWrapper children={<HomeShow />} />} />
                </Route>
                <Route path="/profile">
                  <Route index element={<BgWrapper children={<ProfileShow />} />} />
                </Route>
                <Route path="/aboutus">
                  <Route index element={<BgWrapper children={<AboutusShow />} />} />
                </Route>
                <Route path="/invoices">
                  <Route index element={<Invoices />} />
                </Route>
                <Route path="/operations">
                  <Route index element={<Operations />} />
                  <Route path="show/:id" element={<OperationShow />} />
                </Route>
                <Route path="/deposit/:currency_code">
                  <Route index element={<DepositShow />} />
                </Route>
                <Route path="/withdraw/:currency_code">
                  <Route index element={<WithdrawShow />} />
                </Route>

                {/* admin */}
                <Route path="/dashboard">
                  <Route index element={<AdminWrapper children={<DashboardShow />} />} />
                </Route>
                <Route path="/merchants">
                  <Route index element={<AdminWrapper children={<MerchantList />} />} />
                  <Route path="create" element={<AdminWrapper children={<MerchantCreate />} />} />
                  <Route path="edit/:id" element={<AdminWrapper children={<MerchantEdit />} />} />
                  <Route path="show/:id" element={<AdminWrapper children={<MerchantShow />} />} />
                </Route>
                <Route path="/users">
                  <Route index element={<AdminWrapper children={<UserList />} />} />
                  <Route path="show/:id" element={<AdminWrapper children={<UserShow />} />} />
                  <Route path="edit/:id" element={<AdminWrapper children={<UserEdit />} />} />
                </Route>
                <Route path="/gateways">
                  <Route index element={<AdminWrapper children={<GatewaysList />} />} />
                </Route>
                <Route path="/admin/accounts">
                  <Route index element={<AdminWrapper children={<AdminAccountList />} />} />
                </Route>
                <Route path="/api_tokens">
                  <Route index element={<AdminWrapper children={<ApiTokens />} />} />
                </Route>
                <Route path="/admin/operations">
                  <Route index element={<AdminWrapper children={<AdminOperationsList />} />} />
                </Route>
                <Route path="/admin/invoices">
                  <Route index element={<AdminWrapper children={<AdminInvoicesList />} />} />
                </Route>
                <Route path="/admin/cards">
                  <Route index element={<AdminWrapper children={<AdminCardsList />} />} />
                </Route>
                <Route path="/admin/events">
                  <Route index element={<AdminWrapper children={<AdminEventsList />} />} />
                </Route>
                <Route path="/admin/tariffs">
                  <Route index element={<AdminWrapper children={<AdminTariffsList />} />} />
                  <Route path="create" element={<AdminWrapper children={<AdminTariffCreate />} />} />
                </Route>
                <Route path="/admin/payment_limits">
                  <Route index element={<AdminWrapper children={<AdminPaymentLimitsList />} />} />
                  <Route path="create" element={<AdminWrapper children={<AdminPaymentLimitCreate />} />} />
                </Route>
                <Route path="/admin/payment_providers">
                  <Route index element={<AdminWrapper children={<AdminPaymentProvidersList />} />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
              </Route>
              <Route element={<Outlet />}>

              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ConfigProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  )
}

export default App
