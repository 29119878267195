import {
  useActiveAuthProvider,
  useLogin,
  useTranslate,
  RegisterFormTypes
} from "@refinedev/core";
import { Flex, Form, Input, Typography } from "antd";

import { SubmitButton } from "components/client_ui/SubmitButton";

const { Text } = Typography

export const TwoFactorForm: React.FC = () => {
  const t = useTranslate()

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });

  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => login(values)}
      requiredMark={false}
      initialValues={{}}
    >
      <Flex
        vertical
        align='center'
        gap={64}
      >
        <Flex
          vertical
          align='center'
        >
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.login.fields.two_factor')}
            </Text>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true, message: '',
                },
                {
                  len: 6,
                  message: '',
                }
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input.OTP
                id='code'
                key='code'
                size="large"
                length={6}
              />
            </Form.Item>
          </Flex>
          <Text
            style={{
              height: 17,
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {t('pages.login.titles.enter_two_fa_code')}
          </Text>
        </Flex>
        <Form.Item>
          <SubmitButton
            isLoading={isLoading}
            form={form}
          />
        </Form.Item>
      </Flex>
    </Form>
  )
}