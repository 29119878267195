import React from "react";
import { Layout as AntdLayout } from "antd";

import { ThemedSiderV2 as DefaultSider } from "@refinedev/antd";
import { ThemedHeaderV2 as DefaultHeader } from "@refinedev/antd";
import { RefineThemedLayoutV2Props } from "@refinedev/antd";
import { ThemedLayoutContextProvider } from "@refinedev/antd";

import { IsManagerRole } from "components/IsManagerRole"

const isManager = IsManagerRole();

export const CustomThemedLayoutV2: React.FC<RefineThemedLayoutV2Props> = ({
  children,
  Header,
  Sider,
  Title,
  Footer,
  OffLayoutArea,
  initialSiderCollapsed,
}) => {
  const SiderToRender = Sider ?? DefaultSider;
  const HeaderToRender = Header ?? DefaultHeader;
  const hasSider = !!SiderToRender({ Title });

  return (
    <ThemedLayoutContextProvider
      initialSiderCollapsed={initialSiderCollapsed}
    >
      <AntdLayout
        style={{
          minHeight: "100vh",
        }}
        hasSider={isManager && hasSider}
      >
        {isManager && <SiderToRender Title={Title} />}
        <AntdLayout
          style={{

          }}
        >
          {isManager && <HeaderToRender />}
          <AntdLayout.Content>
            <div
              children={children}
            />
            {isManager && OffLayoutArea && <OffLayoutArea />}
          </AntdLayout.Content>
          {isManager && Footer && <Footer />}
        </AntdLayout>
      </AntdLayout>
    </ThemedLayoutContextProvider>
  );
};