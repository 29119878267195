import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps } from 'antd'
import { IGateway } from 'interfaces'
import { ShortId } from 'components/ShortId'
import { Tag } from 'antd'
import { GatewayType } from 'components/GatewayType'
import { GatewayStatus } from 'components/GatewayStatus'
import { GatewayDirection } from 'components/GatewayDirection'

const GatewaysTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IGateway> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 10,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IGateway>
        ellipsis
        dataIndex="id"
        width={100}
        key="id"
        title={t('pages.gateways.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IGateway>
        width={100}
        ellipsis
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('pages.gateways.fields.currency')}
      />
      <Table.Column<IGateway>
        width={100}
        dataIndex="payment_type"
        key="payment_type"
        title={t('pages.gateways.fields.payment_type')}
        render={(value) => <GatewayType type={value} />}
      />
      <Table.Column<IGateway>
        width={120}
        dataIndex="direction"
        key="direction"
        title={t('pages.gateways.fields.direction')}
        render={(value) => <GatewayDirection direction={value} />}
      />
      <Table.Column<IGateway>
        width={120}
        dataIndex="status"
        key="status"
        title={t('pages.gateways.fields.status')}
        render={(value) => <GatewayStatus status={value} />}
      />
      <Table.Column<IGateway>
        ellipsis
        dataIndex="name"
        key="name"
        title={t('pages.gateways.fields.name')}
      />
    </Table>
  )
}

export default GatewaysTable
