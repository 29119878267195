import React from "react";
import { Flex, Typography } from 'antd'
import { useTranslate } from '@refinedev/core'
import { statusColor } from "../utils";
import { ICurrentUser } from "interfaces";
import { formatPhone } from "../utils";

const { Text } = Typography

export const PersonIconPrimary: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6864)">
        <path d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_120_6864">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const VerifiedText: React.FC<{ verified: boolean }> = ({ verified }) => {
  const t = useTranslate()

  const color = verified ? statusColor('verified') : statusColor('not_verified')
  const status =
    verified ? t('components.person_header.statuses.verified') :
      t('components.person_header.statuses.not_verified')

  return (
    <Text
      style={{
        fontFamily: 'Jost',
        fontSize: 12,
        fontWeight: '400',
        lineHeight: '17.34px',
        color: color,
      }}
    >
      {status}
    </Text>
  )
}

export const PersonHeader: React.FC<{
  user: ICurrentUser
}> = ({
  user
}) => {
    return (
      <Flex
        wrap
        justify='start'
        align='center'
        gap={15}
      >
        <span
          style={{
            display: 'inline-block',
            width: 48,
            height: 48,
            padding: 12,
            borderRadius: 50,
            background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
          }}
          children={<PersonIconPrimary />}
        />
        <Flex
          vertical
          style={{
            height: 40,
          }}
        >
          <div
            style={{
              height: 23,
            }}
          >
            <Text
              style={{
                fontFamily: 'Jost',
                fontSize: 16,
                fontWeight: '500',
                lineHeight: '23.12px',
                color: '#303030',
              }}
            >
              {formatPhone(user.phone)}
            </Text>
          </div>
          <VerifiedText verified={user.verified} />
        </Flex>
      </Flex>
    )
  }