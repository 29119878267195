import React, { ReactNode } from "react";
import { Flex, Typography } from 'antd'
import { useTranslate } from '@refinedev/core'

import { EllipseLink } from 'components/client_ui/EllipseLink';
import { HistoryIcon, InvoicesIcon, SupportIcon } from "./icons";

const { Text } = Typography

const Action: React.FC<{
  to: string
  text: string
  icon: ReactNode
}> = ({
  to,
  text,
  icon,
}) => {
    return (
      <Flex
        vertical
        align='center'
        gap={8}
        style={{
          padding: '0 4px',
        }}
      >
        <Flex
          justify='space-around'
          align='center'
        >
          <EllipseLink to={to}>
            {icon}
          </EllipseLink>
        </Flex>

        <div
          style={{
            height: 17,
            textAlign: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 12,
              fontWeight: '400',
              lineHeight: '17.34px',
              color: '#303030',
            }}
          >
            {text}
          </Text>
        </div>
      </Flex>
    )
  }

export const ManageProfile: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  const t = useTranslate()

  return (
    <Flex
      justify='center'
      align='center'
      gap={24}
      style={Object.assign({
        height: 73,
        padding: '0 16px',
      }, style)}
    >
      <Action to='/operations' text={t('components.manage_profile.titles.history')} icon={<HistoryIcon />} />
      <Action to='/invoices' text={t('components.manage_profile.titles.invoices')} icon={<InvoicesIcon />} />
      <Action to='/home' text={t('components.manage_profile.titles.support')} icon={<SupportIcon />} />
    </Flex>

  )
}