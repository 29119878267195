import React from 'react'
import { operationStatus } from 'interfaces'
import { Tag } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'

export const OperationStatus: React.FC<{ status: operationStatus }> = ({ status }) => {
  const IconMap = {
    created: <SyncOutlined spin />,
    expired: <ClockCircleOutlined />,
    cancelled: <MinusCircleOutlined />,
    rejected: <CloseCircleOutlined />,
    approved: <CheckCircleOutlined />,
    marked_as_paid: <ClockCircleOutlined />,
    wasted: <ExclamationCircleOutlined />,
  }

  const ColorMap = {
    created: 'processing',
    expired: 'error',
    cancelled: 'red',
    rejected: 'magenta',
    approved: 'success',
    marked_as_paid: 'orange',
    wasted: 'orange',
  }

  return (
    <Tag
      icon={IconMap[status]}
      color={ColorMap[status]}
    >
      {status}
    </Tag>
  )
}
