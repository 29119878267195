import { Person } from './person'
import { Switch } from './switch'
import { LanguageSupport } from './language_support'

export const TopMenu: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  return (
    <div
      style={Object.assign({
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
      }, style)}
    >
      <Person />
      <Switch />
      <LanguageSupport />
    </div>
  )
}