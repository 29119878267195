import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space } from 'antd'
import { ICard } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'

const AdminCardsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<ICard> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<ICard>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_cards.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<ICard>
        ellipsis
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('pages.admin_cards.fields.currency')}
      />
      <Table.Column<ICard>
        ellipsis
        dataIndex="masked_number"
        key="masked_number"
        title={t('pages.admin_cards.fields.masked_number')}
        render={(value) => <Tag color='geekblue' children={value} />}
      />
      <Table.Column<ICard>
        dataIndex="user"
        key="user"
        title={t('pages.admin_operations.fields.user')}
        render={(value) =>
          <Space size={1}>
            <Tag><ShortId value={value?.id} /></Tag>
            <Tag color='geekblue'><CopyText value={value?.phone} style={{ fontSize: 12 }} /></Tag>
          </Space>
        }
      />
      <Table.Column<ICard>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_operations.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<ICard>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_operations.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AdminCardsTable
