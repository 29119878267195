import { useEffect, useState } from "react";
import { useTranslate } from "@refinedev/core";
import { Button, FormInstance, Form, Typography, Flex } from "antd";

const { Text } = Typography

const EnabledIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2141_1813)">
        <path d="M16.01 11.3607H4V13.3607H16.01V16.3607L20 12.3607L16.01 8.36066V11.3607Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2141_1813">
          <rect width="24" height="24" fill="white" transform="translate(0 0.360657)" />
        </clipPath>
      </defs>
    </svg>
  );
}

const DisabledIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2141_277)">
        <path d="M16.01 11.3607H4V13.3607H16.01V16.3607L20 12.3607L16.01 8.36066V11.3607Z" fill="#AAAAAA" />
      </g>
      <defs>
        <clipPath id="clip0_2141_277">
          <rect width="24" height="24" fill="white" transform="translate(0 0.360657)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const SubmitButton: React.FC<{
  form: FormInstance
  isLoading?: boolean
}> = ({
  form,
  isLoading
}) => {
    const [submittable, setSubmittable] = useState<boolean>(false);
    const values = Form.useWatch([], form);

    useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    }, [form, values]);

    const t = useTranslate()

    return (
      <Button
        disabled={!submittable}
        htmlType="submit"
        loading={isLoading}

        style={{
          textAlign: 'center',
          height: 56,
          width: 182,
          padding: '16px 24px',
          borderRadius: 60,
          background: submittable ? 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)' : '#F5F5F5',
          border: 0,
          boxShadow: 'none',

        }}
      >
        <Flex
          justify='space-around'
          align='center'
          gap={10}
        >
          <Text
            style={{
              height: 23,
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '700',
              lineHeight: '23.12px',
              color: submittable ? '#FFFFFF' : '#AAAAAA',
            }}
          >
            {t("pages.signup.buttons.confirm")}
          </Text>
          {submittable ? (<EnabledIcon />) : (<DisabledIcon />)}
        </Flex>
      </Button >
    );
  };