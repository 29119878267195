import { Show, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { IEvent } from 'interfaces'
import AdminEventsTable from "pages/admin/events/list_table";

export const EventsList: React.FC<any> = ({ operationId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<IEvent>({
    resource: 'admin/events',

    filters: {
      permanent: [
        {
          field: 'operation_id',
          operator: 'eq',
          value: operationId,
        },
      ]
    }
  })

  return (
    <AdminEventsTable tableProps={tableProps} />
  )
}
