import React from 'react';
import { useTranslate } from '@refinedev/core'
import { useNavigate, Link } from "react-router-dom";
import { Flex, Typography } from 'antd'
import { motion } from "framer-motion";

import { Wrapper } from 'components/client_ui/Wrapper'
import { OperationsList } from 'components/client_ui/OperationsList';

const { Text } = Typography

export const BackIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_133_8671)">
        <path d="M3 12L7 16L7 13L22 13L22 11L7 11L7 8L3 12Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_133_8671">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const LanguageIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1536_7519)">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_1536_7519">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

const SupportIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1536_7520)">
        <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_1536_7520">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Back: React.FC<{
  style?: React.CSSProperties
}> = ({
  style = {}
}) => {
    const navigate = useNavigate();

    return (
      <Flex
        onClick={() => navigate(-1)}
        justify='space-around'
        align='center'
        style={Object.assign({
          height: 24,
          width: 24,
          cursor: 'pointer',
        }, style)}
      >
        <BackIcon />
      </Flex>
    )
  }

export const Operations: React.FC = () => {
  const t = useTranslate()

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: '#FFFFFF',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 32px 10px 32px',
          minHeight: '100vh',
        }}
      >
        <Flex
          vertical
          gap={30}
        >
          <Flex
            justify='space-between'
            align='center'
          >
            <Back />
            <Text
              style={{
                fontFamily: 'Jost',
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '23.12px',
                color: '#303030',
              }}
            >
              {t('pages.operations.titles.history')}
            </Text>
            <Flex
              justify='space-between'
              align='center'
              gap={24}
            >
              <Link
                to="/home"
                children={<LanguageIcon />}
              />
              <Link
                to="/home"
                children={<SupportIcon />}
              />
            </Flex>
          </Flex>
          <div
            style={{
              padding: '0 8px',
            }}
          >
            <OperationsList />
          </div>
        </Flex>
      </Wrapper>
    </motion.div >
  )
}
