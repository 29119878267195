import React, { ReactNode } from "react";

export const Card: React.FC<{
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  children,
  style = {}
}) => {
    return (
      <div
        children={children}
        style={Object.assign({
          padding: 24,
          borderRadius: '30px',
          background: '#FFFFFF',
          boxShadow: '0px 0px 10px 0px #0000001A',
        }, style)}
      />
    )
  }