import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { ListButton, Create, useForm } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'
import { timeZoneOptions, currencyOptions } from 'utils'

import { IMerchant } from 'interfaces'

export const MerchantCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { formProps, saveButtonProps } = useForm<IMerchant>()

  const headerButtons = () => {
    return (
      <>
        <ListButton
          resource="merchants"
          title={t('merchants.merchants')}
        />
      </>
    )
  }

  return (
    <Create
      headerButtons={headerButtons}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          timezone: 'Europe/Moscow',
          currency: 'RUB',
        }}
      >
        <Form.Item
          label={t('pages.merchants.fields.name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="timezone" label={t('pages.merchants.fields.timezone')}>
          <Select
            showSearch
            //@ts-ignore
            options={timeZoneOptions}
          />
        </Form.Item>
        <Form.Item name="currency" label={t('pages.merchants.fields.currency')}>
          <Select options={currencyOptions} />
        </Form.Item>
      </Form>
    </Create>
  )
}
