import {
  useActiveAuthProvider,
  useRegister,
  useTranslate,
  RegisterFormTypes
} from "@refinedev/core";
import { Flex, Form, Input, Typography } from "antd";

import { SubmitButton } from "components/client_ui/SubmitButton";

const { Text } = Typography

export const OtpForm: React.FC = () => {
  const t = useTranslate()

  const authProvider = useActiveAuthProvider();
  const { mutate: register, isLoading } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });

  const [form] = Form.useForm()

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => register(values)}
      requiredMark={false}
      initialValues={{}}
    >
      <Flex
        vertical
        align='center'
        gap={40}
      >
        <Flex
          vertical
          align='center'
        >
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.signup.fields.confirm_phone')}
            </Text>
            <Form.Item
              name="token"
              rules={[
                {
                  required: true, message: '',
                },
                {
                  len: 6, message: '',
                }
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              {/* <Input.OTP
                  size="large"
                  autoFocus
                  length={6}
                /> */}
              <Input
                id='token'
                maxLength={6}
                placeholder={t("pages.signup.placeholders.6_digit_code")}
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Text
            style={{
              height: 17,
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {t('pages.signup.titles.enter_code')}
          </Text>
        </Flex>
        <Flex
          vertical
          align='center'
          gap={24}
        >
          <div
            style={{
              padding: '16px 24px',
            }}
          >
            <Text
              style={{
                height: 23,
                fontFamily: 'Jost',
                fontSize: 16,
                fontWeight: '700',
                lineHeight: '23.12px',
                color: '#303030',
                cursor: 'pointer',
              }}
            >
              {t('pages.signup.buttons.resend_code')}
            </Text>
          </div>
          <Form.Item>
            <SubmitButton
              isLoading={isLoading}
              form={form}
            />
          </Form.Item>
        </Flex>
      </Flex>
    </Form>
  )
}