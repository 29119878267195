import React from 'react';
import { useTranslate } from '@refinedev/core'
import type { CSSProperties } from "react";
import { Grid, Flex, Typography } from 'antd'
import { motion } from "framer-motion";

import { Wrapper } from 'components/client_ui/Wrapper'
import { TopMenu } from 'components/client_ui/TopMenu'
import { Card } from 'components/client_ui/Card'
import { Balance } from 'components/client_ui/Balance';
import { ManageFiat } from 'components/client_ui/ManageFiat';
import { OperationsList } from 'components/client_ui/OperationsList';
import { InvoicesList } from 'components/client_ui/InvoicesList';

const { Text } = Typography

const cardStyle: CSSProperties = {
  gridColumn: 'span 2',
};
const cardStyleMobile: CSSProperties = {
  gridColumn: 'span 2',
};

const CardTitle: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Flex
      justify='start'
      align='center'
      style={{
        height: 23,
        position: 'absolute',
        zIndex: 1,
      }}
    >
      <Text
        style={{
          fontFamily: 'Jost',
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '23.12px',
          color: '#303030',
        }}
      >
        {text}
      </Text>
    </Flex>
  )
}

export const HomeShow: React.FC = () => {
  const t = useTranslate()

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;
  const currentStyle = isMobile ? cardStyleMobile : cardStyle;

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '40px 20px',
          minHeight: '100vh',
        }}
      >
        <TopMenu
          style={{
            marginBottom: 24,
          }}
        />

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 9,
          }}
        >
          <Card
            style={{
              ...currentStyle,
            }}
          >
            <Balance
              style={{
                marginBottom: 40,
              }}
            />
            <ManageFiat currency_code={'RUB'} />
          </Card>

          <Card
            style={{
              ...currentStyle,
              position: 'relative',
              minHeight: 70,
            }}
          >
            <CardTitle text={t('pages.home.titles.last_transactions')} />
            <OperationsList count={3} />
          </Card>
          <Card
            style={{
              gridColumn: 'span 2',
              position: 'relative',
              minHeight: 70,
            }}
          >
            <CardTitle text={t('pages.home.titles.invoices')} />
            <InvoicesList count={3} />
          </Card>
        </div>
      </Wrapper>
    </motion.div >
  )
}
