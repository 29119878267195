import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag, Space } from 'antd'
import { TextField } from '@refinedev/antd'
import { IInvoice } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'
import { formattedAmount } from 'utils'
import BooleanTag from 'components/BooleanTag'

const AdminInvoicesTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IInvoice> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_invoices.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex={['merchant', 'currency', 'code']}
        key="merchant_currency_code"
        title={t('pages.admin_invoices.fields.currency')}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="amount"
        key="amount"
        title={t('pages.admin_invoices.fields.amount')}
        render={(value) => (
          <TextField value={formattedAmount(value)} />
        )}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="status"
        key="status"
        title={t('pages.admin_invoices.fields.status')}
        render={(value) => (
          <Tag color='magenta' children={value} />
        )}
      />
      <Table.Column<IInvoice>
        dataIndex="merchant"
        key="merchant"
        title={t('pages.admin_invoices.fields.merchant')}
        render={(value) =>
          <Space direction='vertical'>
            <Tag><ShortId value={value?.id} /></Tag>
            {value?.name && <Tag color='purple'>{value?.name}</Tag>}
          </Space>
        }
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="idempotency_key"
        key="idempotency_key"
        title={t('pages.admin_invoices.fields.idempotency_key')}
        render={(value) => <Tag color='magenta' children={value} />}
      />
      <Table.Column<IInvoice>
        dataIndex="user"
        key="user"
        title={t('pages.admin_invoices.fields.user')}
        render={(value) =>
          <Space direction='vertical'>
            <Tag><ShortId value={value?.id} /></Tag>
            <Tag color='geekblue'><CopyText value={value?.phone} style={{ fontSize: 12 }} /></Tag>
          </Space>
        }
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="client_trusted"
        key="client_trusted"
        width={100}
        title={t('pages.admin_invoices.fields.client_trusted')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="client_phone_number"
        key="client_phone_number"
        title={t('pages.admin_invoices.fields.client_phone_number')}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="client_id"
        key="client_id"
        title={t('pages.admin_invoices.fields.client_id')}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_invoices.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IInvoice>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_invoices.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AdminInvoicesTable
