import React, { ReactNode } from "react";

export const BgWrapper: React.FC<{
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  children,
  style = {}
}) => {
    return (
      <div
        children={children}
        style={Object.assign({
          minHeight: 360,
          background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
        }, style)}
      />
    )
  }