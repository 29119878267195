import React from "react";
import { useTranslate, useList } from '@refinedev/core'
import { Flex, Typography, Space, Skeleton } from 'antd'
import { DateField } from '@refinedev/antd'
import { useNavigate } from "react-router-dom"

import { Ellipse } from "../Ellipse";
import { PurchaseIcon, TopUpIcon, WithdrawToCard } from "./icons";
import { formatAmount, statusColor, compareDays, formatCurrency } from "../utils";
import { IOperation } from 'interfaces';

const { Text } = Typography

const OperationDate: React.FC<{
  created_at: string
  style?: React.CSSProperties
}> = ({
  created_at,
  style = {}
}) => {
    return (
      <Flex
        justify='end'
        align='center'
        style={Object.assign({
          height: 23,
        }, style)}
      >
        <DateField
          ellipsis
          value={created_at}
          format='MMMM, D'
          style={{
            fontFamily: 'Jost',
            fontSize: 12,
            fontWeight: '400',
            lineHeight: '17.34px',
            color: '#303030',
            textAlign: 'right',
            textTransform: 'capitalize'
          }}
        />
      </Flex>
    )
  }

const Amount: React.FC<{
  kind: string
  amount: string
  currency: string
}> = ({
  kind,
  amount,
  currency
}) => {
    const amountColor = kind === 'wallet_deposit' ? '#00C364' : '#303030';

    return (
      <div
        style={{
          height: 20,
          textAlign: 'right',
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 14,
            fontWeight: '700',
            lineHeight: '20.23px',
            color: amountColor,
          }}
        >
          <Space size={1}>
            {kind === 'wallet_deposit' ? '+' : '—'}
            {formatAmount(amount)}
            {formatCurrency(currency)}
          </Space>
        </Text>
      </div>
    )
  }

const Operation: React.FC<{ data: IOperation }> = ({ data, }) => {
  const t = useTranslate()
  const navigate = useNavigate();

  let icon;
  switch (data.kind) {
    case 'wallet_deposit':
      icon = <TopUpIcon />
      break;
    case 'wallet_withdraw':
      icon = <WithdrawToCard />
      break;
    case 'invoice_payment':
      icon = <PurchaseIcon />
      break;
    default:
      icon = <PurchaseIcon />
      break;
  }

  return (
    <Flex
      wrap
      justify='space-between'
      align='center'
      onClick={() => navigate('/operations/show/' + data.id)}
      style={{
        cursor: 'pointer'
      }}
    >
      <div
        style={{
          height: 44,
          width: 180,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Ellipse>
            {icon}
          </Ellipse>
          <div
            style={{
              height: 34,
            }}
          >
            <div
              style={{
                height: 20,
              }}
            >
              <Text
                ellipsis
                style={{
                  width: 140,
                  fontFamily: 'Jost',
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: '20.23px',
                  color: '#303030',
                }}
              >
                {data?.merchant?.name ?? data?.kind === 'wallet_deposit' ? 'Пополнение' : 'Вывод на карту'}
              </Text>
            </div>
            <div
              style={{
                height: 14,
              }}
            >
              <Text
                style={{
                  fontFamily: 'Jost',
                  fontSize: 10,
                  fontWeight: '400',
                  lineHeight: '14.45px',
                  color: statusColor(data.status),
                }}
              >
                {t('components.operations_list.statuses.' + data.status)}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Amount kind={data.kind} amount={data.amount} currency={data.currency.code} />
    </Flex>
  )
}

export const OperationsList: React.FC<{ count?: number }> = ({ count = 50 }) => {
  const { data: operationsData, isLoading, isError } = useList<IOperation>({
    resource: 'operations',
    filters: [
      {
        field: "statuses",
        operator: "in",
        value: ['created', 'expired', 'cancelled', 'rejected', 'approved', 'marked_as_paid'],
      },
    ],
    pagination: {
      current: 1,
      pageSize: count,
    },
  })
  const operations = operationsData?.data ?? [];

  const listOperations = operations?.map((record, idx) =>
    <div key={record.id}>
      {compareDays(record.created_at, operations[idx - 1]?.created_at) !== true && (
        <OperationDate
          created_at={record.created_at}
          style={{
            marginTop: idx ? 12 : 0,
            marginBottom: 24,
          }}
        />
      )}

      <Operation data={record} />
    </div>
  )

  return (
    <>
      {isLoading ? (
        <Skeleton
          active
          style={{
            marginTop: 45,
          }}
        />
      ) : (
        <Flex
          vertical
          gap={12}
        >
          {listOperations}
        </Flex>
      )}
    </>
  )
}