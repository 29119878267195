import {
  useActiveAuthProvider,
  useLogin,
  useTranslate,
  RegisterFormTypes
} from "@refinedev/core";

import { Flex, Form, Input, Typography } from "antd";
import { formatPhone, checkPhone } from "components/client_ui/utils";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { SubmitButton } from "components/client_ui/SubmitButton";

const { Text } = Typography

export const LoginForm: React.FC = () => {
  const t = useTranslate()

  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy)
  });

  const [form] = Form.useForm()
  const values = Form.useWatch([], form);

  useEffect(() => {
    const phone = form.getFieldValue('phone');
    form.setFieldValue('phone', formatPhone(phone));
  }, [form, values]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => login(values)}
      requiredMark={false}
      initialValues={{}}
    >
      <Flex
        vertical
        align='center'
        gap={64}
      >
        <Flex
          vertical
          align='center'
          gap={8}
        >
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.login.fields.phone')}
            </Text>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true, message: '',
                },
                {
                  validator: checkPhone, message: '',
                }
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input
                id='phone'
                key='phone'
                placeholder='+7'
                maxLength={24}
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Flex
            vertical
            gap={8}
            align='center'
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              {t('pages.login.fields.password')}
            </Text>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true, message: '',
                },
                {
                  min: 8, max: 64, message: '',
                },
              ]}
              style={{
                textAlign: 'center',
                fontFamily: 'Jost',
                fontSize: 20,
                fontWeight: '800',
                lineHeight: '28.9px',
                color: '#303030',
              }}
            >
              <Input
                key='password'
                type='password'
                autoComplete="off"
                maxLength={64}
                placeholder='●●●●●●●●●●●●●●●'
                style={{
                  textAlign: 'center',
                  height: 45,
                  width: 306,
                  padding: '8px 16px',
                  borderRadius: 24,
                  border: '1px solid #EEEEEE',
                  fontFamily: 'Jost',
                  fontSize: 20,
                  fontWeight: '800',
                  lineHeight: '28.9px',
                  color: '#303030',
                  outline: 0,
                  outlineOffset: 0,
                }}
              />
            </Form.Item>
          </Flex>
          <Link
            to='/login'
            style={{
              height: 17,
            }}
          >
            <Text
              style={{
                height: 17,
                fontFamily: 'Jost',
                fontSize: 12,
                fontWeight: '400',
                lineHeight: '17.34px',
                color: '#5D59F6',
              }}
            >
              {t('pages.login.buttons.forgot_password')}
            </Text>
          </Link>
        </Flex>
        <Form.Item>
          <SubmitButton
            isLoading={isLoading}
            form={form}
          />
        </Form.Item>
      </Flex>
    </Form>
  )
}