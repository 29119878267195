import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Descriptions } from 'antd'
import { IPaymentLimit } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'
import { TariffKind } from 'components/TariffKind'

const PaymentLimitsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IPaymentLimit> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IPaymentLimit>
        width={120}
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_payment_limits.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IPaymentLimit>
        width={300}
        dataIndex="kind"
        key="kind"
        title={t('pages.admin_payment_limits.fields.kind')}
        render={(value) => <TariffKind kind={value} />}
      />
      <Table.Column<IPaymentLimit>
        ellipsis
        width={150}
        dataIndex="valid_from"
        key="valid_from"
        title={t('pages.admin_payment_limits.fields.valid_from')}
        render={(value) => <DateField value={value} format='YYYY.MM.DD' />}
      />
      <Table.Column<IPaymentLimit>
        ellipsis
        width={150}
        dataIndex="valid_to"
        key="valid_to"
        title={t('pages.admin_payment_limits.fields.valid_to')}
        render={(value) => <DateField value={value} format='YYYY.MM.DD' />}
      />
      <Table.Column<IPaymentLimit>
        ellipsis
        width={150}
        dataIndex={['currency', 'code']}
        key="currency_code"
        title={t('pages.admin_payment_limits.fields.currency')}
      />
      <Table.Column<IPaymentLimit>
        key="deposit_limits"
        title={t('pages.admin_payment_limits.fields.deposit')}
        render={(_, record) =>
          <Descriptions column={1} style={{ width: 350 }}>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.min_deposit_once')}>
              {record?.min_deposit_once}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_deposit_once')}>
              {record?.max_deposit_once}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_deposit_daily')}>
              {record?.max_deposit_daily}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_deposit_monthly')}>
              {record?.max_deposit_monthly}
            </Descriptions.Item>
          </Descriptions>
        }
      />
      <Table.Column<IPaymentLimit>
        key="withdrawal_limits"
        title={t('pages.admin_payment_limits.fields.withdrawal')}
        render={(_, record) =>
          <Descriptions column={1} style={{ width: 350 }}>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.min_withdrawal_once')}>
              {record?.min_withdrawal_once}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_withdrawal_once')}>
              {record?.max_withdrawal_once}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_withdrawal_daily')}>
              {record?.max_withdrawal_daily}
            </Descriptions.Item>
            <Descriptions.Item label={t('pages.admin_payment_limits.fields.max_withdrawal_monthly')}>
              {record?.max_withdrawal_monthly}
            </Descriptions.Item>
          </Descriptions>
        }
      />
      <Table.Column<IPaymentLimit>
        width={200}
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_payment_limits.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
    </Table>
  )
}

export default PaymentLimitsTable
