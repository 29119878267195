import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, TableProps, Tag } from 'antd'
import { IPaymentProvider } from 'interfaces'
import { ShortId } from 'components/ShortId'
import DateField from 'components/DateField'

const AdminPaymentProvidersTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IPaymentProvider> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 20,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IPaymentProvider>
        ellipsis
        dataIndex="id"
        key="id"
        title={t('pages.admin_payment_providers.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IPaymentProvider>
        ellipsis
        dataIndex="name"
        key="name"
        title={t('pages.admin_payment_providers.fields.name')}
        render={(value) => <Tag color='geekblue' children={value} />}
      />
      <Table.Column<IPaymentProvider>
        ellipsis
        dataIndex="payouts_expiration_time_in_minutes"
        key="payouts_expiration_time_in_minutes"
        title={t('pages.admin_payment_providers.fields.payouts_expiration_time_in_minutes')}
        render={(value) => <Tag>{value}</Tag>}
      />
      <Table.Column<IPaymentProvider>
        ellipsis
        dataIndex="created_at"
        key="created_at"
        title={t('pages.admin_payment_providers.fields.created_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
      <Table.Column<IPaymentProvider>
        ellipsis
        dataIndex="updated_at"
        key="updated_at"
        title={t('pages.admin_payment_providers.fields.updated_at')}
        render={(value) => <DateField style={{ fontSize: 12 }} value={value} />}
      />
    </Table>
  )
}

export default AdminPaymentProvidersTable
