import { useTranslate } from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Flex } from "antd";

const { Text } = Typography

const BackIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_133_8671)">
        <path d="M3 12L7 16L7 13L22 13L22 11L7 11L7 8L3 12Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_133_8671">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HomeButton: React.FC = () => {
  const t = useTranslate()
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate('/home')}
      style={{
        textAlign: 'center',
        height: 56,
        padding: '16px 24px',
        borderRadius: 60,
        background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
        border: 0,
        boxShadow: 'none',
        margin: '0 auto',
      }}
    >
      <Flex
        justify='space-around'
        align='center'
        gap={10}
      >
        <Text
          style={{
            height: 23,
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '700',
            lineHeight: '23.12px',
            color: '#FFFFFF',
          }}
        >
          {t("components.home_button.buttons.home")}
        </Text>
        {<BackIcon />}
      </Flex>
    </Button >
  );
};