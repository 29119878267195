import React from "react";

export const LanguageIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1536_7519)">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_1536_7519">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export const CreditCardIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4869)">
        <path d="M16.6666 3.3335H3.33329C2.40829 3.3335 1.67496 4.07516 1.67496 5.00016L1.66663 15.0002C1.66663 15.9252 2.40829 16.6668 3.33329 16.6668H16.6666C17.5916 16.6668 18.3333 15.9252 18.3333 15.0002V5.00016C18.3333 4.07516 17.5916 3.3335 16.6666 3.3335ZM16.6666 15.0002H3.33329V10.0002H16.6666V15.0002ZM16.6666 6.66683H3.33329V5.00016H16.6666V6.66683Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BankAccountIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_827)">
        <path d="M5.41663 8.3335H3.74996V14.1668H5.41663V8.3335ZM10.4166 8.3335H8.74996V14.1668H10.4166V8.3335ZM17.5 15.8335H1.66663V17.5002H17.5V15.8335ZM15.4166 8.3335H13.75V14.1668H15.4166V8.3335ZM9.58329 2.71683L13.925 5.00016H5.24163L9.58329 2.71683ZM9.58329 0.833496L1.66663 5.00016V6.66683H17.5V5.00016L9.58329 0.833496Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_827">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SBPIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2668)">
        <path d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 19H7V5H17V19Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_1_2668">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NewCardIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4792)">
        <path d="M10.8334 5.83317H9.16669V9.1665H5.83335V10.8332H9.16669V14.1665H10.8334V10.8332H14.1667V9.1665H10.8334V5.83317ZM10 1.6665C5.40002 1.6665 1.66669 5.39984 1.66669 9.99984C1.66669 14.5998 5.40002 18.3332 10 18.3332C14.6 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6 1.6665 10 1.6665ZM10 16.6665C6.32502 16.6665 3.33335 13.6748 3.33335 9.99984C3.33335 6.32484 6.32502 3.33317 10 3.33317C13.675 3.33317 16.6667 6.32484 16.6667 9.99984C16.6667 13.6748 13.675 16.6665 10 16.6665Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4792">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

export const RUBIcon: React.FC = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4897)">
        <path d="M8.40803 5.5H10.796V19.5H8.40803V5.5ZM9.75251 7.58V5.5H12.7224C13.7525 5.5 14.6622 5.64 15.4515 5.92C16.2408 6.18667 16.8629 6.62667 17.3177 7.24C17.7726 7.84 18 8.64 18 9.64C18 10.6267 17.7726 11.4333 17.3177 12.06C16.8629 12.6733 16.2408 13.12 15.4515 13.4C14.6622 13.6667 13.7525 13.8 12.7224 13.8H9.75251V11.72H12.7224C13.3512 11.72 13.8863 11.6533 14.3278 11.52C14.7692 11.3733 15.1171 11.1467 15.3712 10.84C15.6254 10.5333 15.7525 10.1333 15.7525 9.64C15.7525 9.16 15.6254 8.76667 15.3712 8.46C15.1171 8.15333 14.7692 7.93333 14.3278 7.8C13.8863 7.65333 13.3512 7.58 12.7224 7.58H9.75251ZM10.5151 17.62V15.68H14.6288V17.62H10.5151ZM6 13.74V11.8H10.1137V13.74H6ZM6 17.62V15.68H10.1137V17.62H6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4897">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
};

export const TouchIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_982)">
        <path d="M18.19 12.44L14.95 10.82C16.24 9.82 17.07 8.26 17.07 6.5C17.07 3.47 14.6 1 11.57 1C8.54 1 6.07 3.47 6.07 6.5C6.07 8.63 7.29 10.48 9.07 11.39V14.65C6.92 14.19 7.05 14.21 6.81 14.21C6.28 14.21 5.78 14.42 5.4 14.8L4 16.22L9.09 21.31C9.52 21.75 10.12 22 10.74 22H17.04C18.02 22 18.85 21.3 19.01 20.33L19.81 15.62C20.03 14.32 19.38 13.04 18.19 12.44ZM17.84 15.29L17.04 20H10.74C10.65 20 10.57 19.96 10.5 19.9L6.82 16.22L11.07 17.11V6.5C11.07 6.22 11.29 6 11.57 6C11.85 6 12.07 6.22 12.07 6.5V12.5H13.83L17.29 14.23C17.69 14.43 17.91 14.86 17.84 15.29ZM8.07 6.5C8.07 4.57 9.64 3 11.57 3C13.5 3 15.07 4.57 15.07 6.5C15.07 7.45 14.69 8.31 14.07 8.94V6.5C14.07 5.12 12.95 4 11.57 4C10.19 4 9.07 5.12 9.07 6.5V8.94C8.45 8.31 8.07 7.45 8.07 6.5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1_982">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

export const TimeIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_466)">
        <path d="M6 2V8H6.01L6 8.01L10 12L6 16L6.01 16.01H6V22H18V16.01H17.99L18 16L14 12L18 8.01L17.99 8H18V2H6ZM16 16.5V20H8V16.5L12 12.5L16 16.5ZM12 11.5L8 7.5V4H16V7.5L12 11.5Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1_466">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

export const CheckIcon: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1518_4076)">
        <path d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40ZM33.18 15.16L20 28.34L14.82 23.18L12 26L20 34L36 18L33.18 15.16Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1518_4076">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};

