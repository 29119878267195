import { useNavigate } from "react-router-dom";
import { Flex } from 'antd'

const CloseIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6866)">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6866">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export const CloseBtn: React.FC<{
  style?: React.CSSProperties
}> = ({
  style = {}
}) => {
    const navigate = useNavigate();

    return (
      <Flex
        onClick={() => navigate('/home')}
        justify='space-around'
        align='center'
        style={Object.assign({
          height: 48,
          width: 48,
          cursor: 'pointer',
        }, style)}
      >
        <CloseIcon />
      </Flex>
    )
  }