import React, { ReactNode } from "react";
import { Link } from 'react-router-dom'

export const EllipseLink: React.FC<{
  to: string
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  to,
  children,
  style = {}
}) => {
    return (
      <Link
        to={to}
        style={Object.assign({
          display: 'inline-block',
          width: 48,
          height: 48,
          padding: 10,
          background: 'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%) border-box',
          border: '2px solid transparent',
          borderRadius: 50,
        }, style)}
        children={children}
      />
    )
  }