import {
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'

import { List, useTable } from '@refinedev/antd'
import GatewaysTable from './list_table'
import { IGateway } from 'interfaces'

export const GatewaysList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps } = useTable<IGateway>({
    resource: 'admin/gateways',
  })

  return (
    <List>      
      <GatewaysTable tableProps={tableProps} />
    </List>
  )
}
