import React, { ReactNode } from "react";
import { Grid } from "antd";

export const AdminWrapper: React.FC<{
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  children,
  style = {}
}) => {
    const breakpoint = Grid.useBreakpoint();
    const isMobile = typeof breakpoint.lg === "undefined" ? true : !breakpoint.lg;

    return (
      <div
        children={children}
        style={Object.assign({
          minHeight: 360,
          marginTop: isMobile ? 34 : 0,
          padding: isMobile ? 12 : 24,
        }, style)}
      />
    )
  }