import { IResourceComponentsProps, useCan, useMany, useTranslate } from '@refinedev/core'
import { Table, Space, TableProps, Skeleton, Tag } from 'antd'
import { IDealing, IMerchant, IUser } from 'interfaces'
import UserDealing from 'components/UserDealing'
import AddUserDealingButton from 'components/AddUserDealingButton'
import { compactArray } from 'utils'
import { ShortId } from 'components/ShortId'
import { ShowButton } from '@refinedev/antd'
import BooleanTag from 'components/BooleanTag'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'

const UsersTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IUser>; rolesReadonly?: boolean }
> = ({ tableProps, rolesReadonly = false }) => {
  const t = useTranslate()

  const merchantsIds = compactArray(
    tableProps?.dataSource?.flatMap((item) =>
      item.dealings.map((dealings) => dealings.merchant_id)
    ) ?? []
  )

  const { data: merchantsData, isLoading: merchantsLoading } = useMany<IMerchant>({
    resource: 'merchants',
    ids: merchantsIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 100,
      },
    },
    queryOptions: {
      enabled: merchantsIds.length > 0,
    },
  })

  const { data: canCreateDealing } = useCan({
    resource: 'dealings',
    action: 'create',
  })

  const { data: canListDealings } = useCan({
    resource: 'dealings',
    action: 'list',
  })

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 10,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IUser>
        ellipsis
        dataIndex="id"
        width={100}
        key="id"
        title={t('pages.users.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IUser>
        ellipsis
        dataIndex="phone"
        key="phone"
        width={150}
        title={t('pages.users.fields.phone')}
        render={(value) => <Tag color='geekblue'><CopyText value={value} style={{ fontSize: 12 }} /></Tag>}
      />
      <Table.Column<IUser>
        ellipsis
        dataIndex="telegram_id"
        key="telegram_id"
        width={150}
        title={t('pages.users.fields.telegram_id')}
        render={(value) => <CopyText value={value} style={{ fontSize: 12 }} />}
      />
      <Table.Column<IUser>
        ellipsis
        dataIndex="verified"
        key="verified"
        width={100}
        title={t('pages.users.fields.verified')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<IUser>
        ellipsis
        dataIndex="trusted"
        key="trusted"
        width={100}
        title={t('pages.users.fields.trusted')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<IUser>
        ellipsis
        dataIndex="email"
        key="email"
        width={150}
        title={t('pages.users.fields.email')}
        render={(value) => <CopyText value={value} />}
      />
      {canListDealings?.can && (
        <Table.Column<IUser>
          dataIndex="dealings"
          key="dealings"
          title={t('pages.users.fields.dealings')}
          render={(value: IDealing[], record) => {
            if (merchantsIds.length && merchantsLoading) {
              return <Skeleton.Input active />
            }

            return (
              <Space wrap>
                {value.map((dealing) => (
                  <UserDealing
                    readonly={rolesReadonly}
                    key={dealing.id}
                    dealing={dealing}
                    merchantsData={merchantsData}
                  />
                ))}
                {canCreateDealing?.can && !rolesReadonly && (
                  <AddUserDealingButton id={record.id} />
                )}
              </Space>
            )
          }}
        />
      )}
      <Table.Column<IUser>
        ellipsis
        dataIndex="two_factor_verification_enabled"
        key="two_factor_verification_enabled"
        width={100}
        title={t('pages.users.fields.two_factor_verification_enabled')}
        render={(value) => <BooleanTag value={value} />}
      />
      <Table.Column<IUser>
        width={200}
        dataIndex="created_at"
        key="created_at"
        title={t('pages.users.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IUser>
        dataIndex="actions"
        title={t('pages.users.fields.actions')}
        render={(_, record) => (
          <Space>
            <ShowButton resource="users" size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  )
}

export default UsersTable
