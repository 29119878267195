import {
  useLogout,
  useTranslate,
} from '@refinedev/core'

import { Typography, Flex } from 'antd'

const { Text } = Typography;

const BackIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6987)">
        <path d="M15.91 7.41L14.5 6L8.5 12L14.5 18L15.91 16.59L11.33 12L15.91 7.41Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6987">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogoutBtn: React.FC<{
  style?: React.CSSProperties
}> = ({
  style = {}
}) => {
    const t = useTranslate()
    const { mutate: mutateLogout } = useLogout()

    return (
      <Flex
        onClick={() => mutateLogout()}
        justify='center'
        align='center'
        gap={8}
        style={Object.assign({
          height: 24,
          cursor: 'pointer',
        }, style)}
      >
        <BackIcon />
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '400',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {t('pages.signup.buttons.goToStart')}
        </Text>
      </Flex>
    )
  }