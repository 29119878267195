import React from 'react'
import { operationKind } from 'interfaces'
import { Tag } from 'antd'

export const OperationKind: React.FC<{ kind: operationKind }> = ({ kind }) => {
  const ColorMap = {
    wallet_deposit: 'green',
    wallet_withdraw: 'magenta',
    merchant_deposit: 'success',
    merchant_withdraw: 'red',
    invoice_payment: 'cyan'
  }

  return (
    <Tag
      color={ColorMap[kind]}
    >
      {kind}
    </Tag>
  )
}
