import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { Table, Space, TableProps } from 'antd'
import { IMerchant } from 'interfaces'
import { ShortId } from 'components/ShortId'
import { ShowButton } from '@refinedev/antd'
import DateField from 'components/DateField'
import { CopyText } from 'components/CopyText'

const MechantsTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IMerchant> }
> = ({ tableProps }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
            ...tableProps.pagination,
            pageSize: 10,
            position: ['bottomLeft'],
            size: 'small',
          }
          : false
      }
    >
      <Table.Column<IMerchant>
        ellipsis
        width={100}
        dataIndex="id"
        key="id"
        title={t('pages.merchants.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IMerchant>
        dataIndex="name"
        key="name"
        title={t('pages.merchants.fields.name')}
        render={(value) => <CopyText value={value} />}
      />
      <Table.Column<IMerchant>
        width={100}
        dataIndex="currency"
        key="currency"
        title={t('pages.merchants.fields.currency')}
      />
      <Table.Column<IMerchant>
        width={200}
        dataIndex="created_at"
        key="created_at"
        title={t('pages.merchants.fields.created_at')}
        render={(value) => <DateField value={value} />}
      />
      <Table.Column<IMerchant>
        width={200}
        dataIndex="actions"
        title={t('pages.merchants.fields.actions')}
        render={(_, record) => (
          <Space>
            <ShowButton resource="merchants" size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  )
}

export default MechantsTable
