import { Show, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { IAccount } from 'interfaces'
import AccountsTable from '../admin/accounts/list_table'

export const AccountsList: React.FC<any> = ({ userId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<IAccount>({
    resource: 'admin/accounts',

    filters: {
      permanent: [
        {
          field: 'user_id',
          operator: 'eq',
          value: userId,
        },
      ]
    }
  })

  return (
    <Show
      title={t('pages.users.titles.accounts')}
      headerButtons={() => <></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <AccountsTable tableProps={tableProps} />
    </Show>
  )
}
