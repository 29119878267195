import React, { ReactNode } from "react";

import { Flex, Typography } from 'antd'
import { useTranslate } from '@refinedev/core'

import { EllipseLink } from 'components/client_ui/EllipseLink';
import { TopUpIcon, TransferIcon, InvoicesIcon, ShopIcon } from "./icons";

const { Text } = Typography

const Action: React.FC<{
  to: string
  text: string
  icon: ReactNode
}> = ({
  to,
  text,
  icon,
}) => {
    return (
      <Flex
        vertical
        align='center'
        gap={8}
        style={{
          padding: '0 4px',
        }}
      >
        <Flex
          justify='space-around'
        >
          <EllipseLink to={to}>
            {icon}
          </EllipseLink>
        </Flex>

        <div
          style={{
            height: 20,
            textAlign: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '400',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {text}
          </Text>
        </div>
      </Flex>
    )
  }

export const ManageFiat: React.FC<{ currency_code: string; style?: React.CSSProperties }> = ({ currency_code, style = {} }) => {
  const t = useTranslate()

  const currency = currency_code?.toLowerCase().trim()
  const deposit_link = currency_code ? '/deposit/' + currency : '/home'
  const withdraw_link = currency_code ? '/withdraw/' + currency : '/home'

  return (
    <Flex
      wrap
      justify='space-around'
      gap={16}
      style={Object.assign({
        // height: 76,
      }, style)}
    >
      <Action to={deposit_link} text={t('components.manage_fiat.titles.top_up_account')} icon={<TopUpIcon />} />
      <Action to={withdraw_link} text={t('components.manage_fiat.titles.money_transfer')} icon={<TransferIcon />} />
      <Action to='/invoices' text={t('components.manage_fiat.titles.invoices')} icon={<InvoicesIcon />} />
      <Action to='/home' text={t('components.manage_fiat.titles.shop')} icon={<ShopIcon />} />
    </Flex>
  )
}