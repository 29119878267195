import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Flex, Spin } from 'antd';
import { LogoBlack } from "components/client_ui/LogoBlack";

import "@fontsource/jost";
import "@fontsource/jost/400.css";
import "@fontsource/jost/500.css";
import "@fontsource/jost/600.css";
import "@fontsource/jost/700.css";
import "@fontsource/jost/800.css";
import "@fontsource/jost/900.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

function Loading() {
  return (
    <Flex
      vertical
      justify='center'
      align="center"
      gap={25}
      style={{
        height: '100vh',
      }}
    >
      <LogoBlack />
      <Spin size="large" />
    </Flex>
  )
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
