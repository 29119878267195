import React from 'react';
import { useGetIdentity, useTranslate } from '@refinedev/core'
import { Flex, Typography } from 'antd'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom'

import { ICurrentUser } from 'interfaces';
import { Wrapper } from 'components/client_ui/Wrapper'
import { Card } from 'components/client_ui/Card'
import { ManageProfile } from 'components/client_ui/ManageProfile';
import { CloseBtn } from 'components/client_ui/CloseBtn';
import { ExitBtn } from 'components/client_ui/ExitBtn';
import { PersonHeader } from 'components/client_ui/PersonHeader';

const { Text } = Typography

const RightIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6887)">
        <path d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Account: React.FC = () => {
  const t = useTranslate()

  return (
    <Flex
      vertical
      gap={20}
      style={{

      }}
    >
      <Text
        style={{
          fontFamily: 'Jost',
          fontSize: 12,
          fontWeight: '700',
          lineHeight: '17.34px',
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          color: '#303030',
        }}
      >
        {t('pages.profile.titles.account')}
      </Text>
      <Link
        to="/home"
        style={{
          height: 24,
        }}
      >
        <Flex
          justify='space-between'
          align='center'
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '400',
              lineHeight: '23.12px',
              color: '#303030',
            }}
          >
            {t('pages.profile.titles.verification')}
          </Text>
          <RightIcon />
        </Flex>
      </Link>
      <Link
        to="/home"
        style={{
          height: 24,
        }}
      >
        <Flex
          justify='space-between'
          align='center'
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '400',
              lineHeight: '23.12px',
              color: '#303030',
            }}
          >
            {t('pages.profile.titles.safety')}
          </Text>
          <RightIcon />
        </Flex>
      </Link>
    </Flex>
  )
}

const LinkedAccounts: React.FC = () => {
  const t = useTranslate()

  return (
    <Flex
      vertical
      gap={20}
      style={{

      }}
    >
      <Text
        style={{
          fontFamily: 'Jost',
          fontSize: 12,
          fontWeight: '700',
          lineHeight: '17.34px',
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
          color: '#303030',
        }}
      >
        {t('pages.profile.titles.linked_accounts')}
      </Text>
      <Link
        to="/home"
        style={{
          height: 24,
        }}
      >
        <Flex
          justify='space-between'
          align='center'
        >
          <Text
            style={{
              fontFamily: 'Jost',
              fontSize: 16,
              fontWeight: '400',
              lineHeight: '23.12px',
              color: '#303030',
            }}
          >
            {t('pages.profile.titles.bank_accounts')}
          </Text>
          <RightIcon />
        </Flex>
      </Link>
    </Flex>
  )
}

const SubMenu: React.FC<{
  to: string
  text: string
}> = ({
  to,
  text
}) => {
    return (
      <Link
        to={to}
        style={{
          height: 23,
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 16,
            fontWeight: '400',
            lineHeight: '23.12px',
            color: '#303030',
          }}
        >
          {text}
        </Text>
      </Link>
    )
  }

const Info: React.FC = () => {
  const t = useTranslate()

  return (
    <Flex
      vertical
      gap={24}
      style={{

      }}
    >
      <SubMenu to='/aboutus' text={t('pages.profile.titles.aboutus')} />
      <SubMenu to='/home' text={t('pages.profile.titles.rules')} />
    </Flex>
  )
}

export const ProfileShow: React.FC = () => {
  const t = useTranslate()
  const { data: user } = useGetIdentity<ICurrentUser>()

  return (
    <motion.div
      animate={{
        opacity: [0, 1],
      }}
      transition={{
        duration: 0.35,
        ease: 'easeInOut',
      }}

      style={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(258.2deg, #DDF7FF 0%, #C5DCFF 100%)',
      }}
    >
      <Wrapper
        style={{
          padding: '48px 8px 10px 8px',
        }}
      >
        <Card
          style={{
            position: 'relative',
            padding: 12,
            minHeight: 'calc(100vh - 58px)',
            boxShadow: '0px 0px 10px 0px #00000026',
            paddingBottom: 120,
          }}
        >
          <CloseBtn
            style={{
              position: 'absolute',
              top: 12,
              right: 12,
              zIndex: 1,
            }}
          />
          <ExitBtn
            style={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translate(-50%)',
              zIndex: 1,
            }}
          />
          <Flex
            vertical
            gap={20}
          >
            {user?.phone && <PersonHeader user={user} />}
            <ManageProfile />
            <Flex
              vertical
              gap={35}
              style={{
                padding: 12,
              }}
            >
              <Account />
              <LinkedAccounts />
              <div
                style={{
                  height: 1,
                  background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
                }}
              />
              <Info />
            </Flex>
          </Flex>
        </Card>
      </Wrapper>
    </motion.div >
  )
}
