import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { ListButton, Create, useForm } from '@refinedev/antd'
import {
  Form,
  InputNumber,
  Select,
  Divider,
  Typography,
  Row,
  Col,
  DatePicker,
} from 'antd'
import { useEffect } from 'react'
import { currencyOptions, tariffKindsOptions } from 'utils'
import { IPaymentLimit } from 'interfaces'
import dayjs from 'dayjs'
import { TariffKind } from 'components/TariffKind'

export const AdminPaymentLimitCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { form, formProps, saveButtonProps } = useForm<IPaymentLimit>({
    resource: 'admin/payment_limits'
  })

  const valid_from_not_used = Form.useWatch('valid_from_not_used', form)

  useEffect(() => {
    form.setFieldsValue({
      valid_from: valid_from_not_used ? valid_from_not_used.format('YYYY-MM-DD') : null,
    })
  }, [valid_from_not_used, form])

  const headerButtons = () => {
    return (
      <ListButton resource="admin/payment_limits" />
    )
  }

  return (
    <Create
      headerButtons={headerButtons}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          kind: 'not_trusted_not_verified',
          valid_from_not_used: dayjs.utc().add(1, 'day'),
          currency_code: 'RUB',
        }}
      >
        <Form.Item name="valid_from" rules={[{ required: true, },]} hidden />

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="kind"
              label={t('pages.admin_payment_limits.fields.kind')}
              rules={[{ required: true, },]}
            >
              <Select
                options={tariffKindsOptions}
                optionRender={(option) => <TariffKind kind={option.data.value} />}
                labelRender={(option) => <TariffKind kind={option.value} />}
              />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              name="currency_code"
              label={t('pages.admin_payment_limits.fields.currency')}
              rules={[{ required: true, },]}
            >
              <Select options={currencyOptions} />
            </Form.Item>
          </Col>
          <Col md={6} xs={12}>
            <Form.Item
              name="valid_from_not_used"
              label={t('pages.admin_payment_limits.fields.valid_from')}
              rules={[{ required: true, },]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                minDate={dayjs.utc()}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left' orientationMargin="0">
          <Typography.Title level={3}>{t('pages.admin_payment_limits.fields.deposit')}</Typography.Title>
        </Divider>

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="min_deposit_once"
              label={t('pages.admin_payment_limits.fields.min_deposit_once')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_deposit_once"
              label={t('pages.admin_payment_limits.fields.max_deposit_once')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_deposit_daily"
              label={t('pages.admin_payment_limits.fields.max_deposit_daily')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_deposit_monthly"
              label={t('pages.admin_payment_limits.fields.max_deposit_monthly')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left' orientationMargin="0">
          <Typography.Title level={3}>{t('pages.admin_payment_limits.fields.withdrawal')}</Typography.Title>
        </Divider>

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="min_withdrawal_once"
              label={t('pages.admin_payment_limits.fields.min_withdrawal_once')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_withdrawal_once"
              label={t('pages.admin_payment_limits.fields.max_withdrawal_once')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_withdrawal_daily"
              label={t('pages.admin_payment_limits.fields.max_withdrawal_daily')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="max_withdrawal_monthly"
              label={t('pages.admin_payment_limits.fields.max_withdrawal_monthly')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
