import { IAccessControlContext } from '@refinedev/core'
import { USER_KEY } from './constants'

export const accessControlProvider: Required<IAccessControlContext> = {
  options: {
    buttons: {
      enableAccessControl: true,
      hideIfUnauthorized: true,
    },
  },
  can: async ({ resource, action, params }) => {
    const userData = localStorage.getItem(USER_KEY)
    const roles = userData ? JSON.parse(userData)?.roles : []

    // client
    if (resource === undefined) {
      return Promise.resolve({ can: true })
    }
    if (resource === 'home') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'profile') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'aboutus') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'invoices') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'operations') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'deposit') {
      return Promise.resolve({ can: true })
    }
    if (resource === 'withdraw') {
      return Promise.resolve({ can: true })
    }

    // admin
    if (resource === 'dashboard') {
      return Promise.resolve({ can: true })
    }

    // GOD mode
    // unless dev
    if (roles.includes('admin')) {
      return Promise.resolve({ can: true })
    }

    // GOD mode
    if (roles.includes('developer')) {
      return Promise.resolve({ can: true })
    }

    // Support
    if (roles.includes('support')) {
      if (resource === 'merchants' && (action === 'list' || action === 'filter')) {
        return Promise.resolve({ can: true })
      }
    }

    // Merchant_Admin
    if (roles.includes('merchant_admin')) {
      if (resource === 'merchants' && (
        action === 'show' ||
        action === 'list' ||
        action === 'edit' ||
        action === 'filter'
      )) {
        return Promise.resolve({ can: true })
      }
      if (resource === 'api_tokens') {
        return Promise.resolve({ can: true })
      }
      if (resource === 'invoices' && action === 'list') {
        return Promise.resolve({ can: true })
      }
    }

    return Promise.resolve({ can: false })
  },
}
