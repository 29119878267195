import React from "react";

export const CreditCardIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_4869)">
        <path d="M16.6666 3.3335H3.33329C2.40829 3.3335 1.67496 4.07516 1.67496 5.00016L1.66663 15.0002C1.66663 15.9252 2.40829 16.6668 3.33329 16.6668H16.6666C17.5916 16.6668 18.3333 15.9252 18.3333 15.0002V5.00016C18.3333 4.07516 17.5916 3.3335 16.6666 3.3335ZM16.6666 15.0002H3.33329V10.0002H16.6666V15.0002ZM16.6666 6.66683H3.33329V5.00016H16.6666V6.66683Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_4869">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BankAccountIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2421_827)">
        <path d="M5.41663 8.3335H3.74996V14.1668H5.41663V8.3335ZM10.4166 8.3335H8.74996V14.1668H10.4166V8.3335ZM17.5 15.8335H1.66663V17.5002H17.5V15.8335ZM15.4166 8.3335H13.75V14.1668H15.4166V8.3335ZM9.58329 2.71683L13.925 5.00016H5.24163L9.58329 2.71683ZM9.58329 0.833496L1.66663 5.00016V6.66683H17.5V5.00016L9.58329 0.833496Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_2421_827">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SBPIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2668)">
        <path d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 19H7V5H17V19Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_1_2668">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
