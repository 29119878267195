export const API_SUBDOMAIN = process.env.REACT_APP_BACKEND_SUBDOMAIN
export const BACKEND_URL = process.env.REACT_APP_FORTUNE_BACKEND_URL
export const TOKEN_KEY = 'fortune-jwt-token'
export const USER_KEY = 'fortune-user'
export const TWOFA_KEY = 'fortune-2fa'
export const SIGNUP_ID_KEY = 'fortune-signup-id'
export const SIGNUP_TOKEN_KEY = 'fortune-signup-token'
export const SIGNUP_PASSWORD_KEY = 'fortune-signup-password'
export const SIGNUP_PHONE_KEY = 'fortune-signup-phone'
export const SIGNUP_FINISH_KEY = 'fortune-signup-finish'
export const HIDE_WELCOME_KEY = 'fortune-hide-welcome'