import { Typography, Flex, Space, Skeleton } from "antd"
import { useTranslate, useGetIdentity, useList } from "@refinedev/core"
import { ICurrentUser } from 'interfaces'
import { formatPhone, formatAmount, formatCurrency } from "components/client_ui/utils"
import { IAccount } from 'interfaces'
import { useEffect } from 'react';

const { Text } = Typography

const LogoBlack: React.FC = () => {
  return (
    <svg width="40" height="14" viewBox="0 0 295 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M269.789 52.1688V39.7477H294.844V52.1688H269.789ZM269.789 14.9055V2.48438H294.844V14.9055H269.789ZM269.789 32.6499V20.5837H293.424V32.6499H269.789ZM259 2.48438H273.905V52.1688H259V2.48438Z" fill="black" />
      <path d="M239 2.09091H251V46L225 24.9714V43.9091H213V0L239 21.0286V2.09091Z" fill="black" />
      <rect x="51.3599" y="50.6458" width="191.76" height="47.449" rx="20" fill="url(#paint0_linear_1501_11914)" />
      <path d="M168 2H181.214V28.7568C181.214 30.4216 181.635 31.7892 182.476 32.8595C183.357 33.8901 184.698 34.4054 186.5 34.4054C188.302 34.4054 189.623 33.8901 190.464 32.8595C191.345 31.7892 191.786 30.4216 191.786 28.7568V2H205V29.9459C205 32.9189 204.499 35.436 203.498 37.4973C202.537 39.5189 201.196 41.164 199.474 42.4324C197.752 43.6613 195.77 44.5532 193.528 45.1081C191.325 45.7027 188.983 46 186.5 46C184.017 46 181.655 45.7027 179.412 45.1081C177.21 44.5532 175.248 43.6613 173.526 42.4324C171.804 41.164 170.443 39.5189 169.442 37.4973C168.481 35.436 168 32.9189 168 29.9459V2Z" fill="black" />
      <path d="M127 13.7V2H164V13.7H152.065V44H138.935V13.7H127Z" fill="black" />
      <path d="M102.04 26H115.254L127 44H112.024L102.04 26ZM90 2H102.921V44H90V2ZM98.3397 13.1V2H107.325C111.358 2 114.628 2.62 117.133 3.86C119.678 5.1 121.538 6.8 122.713 8.96C123.926 11.12 124.533 13.6 124.533 16.4C124.533 19.16 123.926 21.64 122.713 23.84C121.538 26 119.678 27.7 117.133 28.94C114.628 30.18 111.358 30.8 107.325 30.8H98.3397V20.9H106.151C107.247 20.9 108.148 20.76 108.852 20.48C109.557 20.16 110.086 19.7 110.438 19.1C110.83 18.5 111.025 17.8 111.025 17C111.025 16.16 110.83 15.46 110.438 14.9C110.086 14.3 109.557 13.86 108.852 13.58C108.148 13.26 107.247 13.1 106.151 13.1H98.3397Z" fill="black" />
      <path d="M52.4423 22.7067C52.4423 24.7013 52.8077 26.4613 53.5385 27.9867C54.2692 29.512 55.3077 30.7049 56.6538 31.5653C58 32.3867 59.6154 32.7973 61.5 32.7973C63.3846 32.7973 65 32.3867 66.3461 31.5653C67.6923 30.7049 68.7308 29.512 69.4615 27.9867C70.1923 26.4613 70.5577 24.7013 70.5577 22.7067C70.5577 20.6729 70.2115 18.9129 69.5192 17.4267C68.8269 15.9013 67.8077 14.728 66.4615 13.9067C65.1538 13.0462 63.5 12.616 61.5 12.616C59.6154 12.616 58 13.0462 56.6538 13.9067C55.3077 14.728 54.2692 15.9013 53.5385 17.4267C52.8077 18.9129 52.4423 20.6729 52.4423 22.7067ZM39 22.7067C39 19.4213 39.5769 16.4489 40.7308 13.7893C41.8846 11.1298 43.4808 8.84178 45.5192 6.92533C47.5577 5.00889 49.9423 3.54222 52.6731 2.52533C55.4038 1.50844 58.3462 1 61.5 1C64.7308 1 67.7115 1.50844 70.4423 2.52533C73.1731 3.54222 75.5577 5.00889 77.5961 6.92533C79.6346 8.84178 81.2115 11.1298 82.3269 13.7893C83.4423 16.4489 84 19.4213 84 22.7067C84 25.992 83.4423 29.0036 82.3269 31.7413C81.25 34.44 79.7115 36.7867 77.7115 38.7813C75.7115 40.776 73.3269 42.3013 70.5577 43.3573C67.8269 44.4524 64.8077 45 61.5 45C58.1923 45 55.1538 44.4524 52.3846 43.3573C49.6538 42.3013 47.2885 40.776 45.2885 38.7813C43.2885 36.7867 41.7308 34.44 40.6154 31.7413C39.5385 29.0036 39 25.992 39 22.7067Z" fill="black" />
      <path d="M10.7886 15.6153V2.48438H33.0047V15.6153H10.7886ZM10.7886 34.7793V22.713H32.2949V34.7793H10.7886ZM0 2.48438H14.9053V52.1688H0V2.48438Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M115.966 73.4782L112.816 80.5469L113.438 82.3782L110.655 87.4692H101.498L115.966 60L130.433 87.4692H121.276L118.566 82.6346L119.115 80.5469L115.966 73.4782ZM144.544 61.8313H136.486V87.4692H154.982V80.6935H144.544V61.8313ZM161.215 61.8313H169.273V80.6935H179.711V87.4692H161.215V61.8313ZM204.441 81.0597H193.636V77.3972H203.708V71.1708H193.636V68.2408H204.441V61.8313H185.945V87.4692H204.441V81.0597ZM210.292 61.8313V68.9733H217.617V87.4692H225.675V68.9733H233V61.8313H210.292ZM91.9486 61.8315L87.993 73.8813L81.3272 60.5496L74.6613 73.8813L70.7058 61.8315H61L72.7202 89.1175L81.3272 76.3718L89.9342 89.1175L101.654 61.8315H91.9486Z" fill="url(#paint0_linear_1536_7514)" />
      <defs>
        <linearGradient id="paint0_linear_1501_11914" x1="179.12" y1="57.6457" x2="55.5974" y2="115.727" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DDF7FF" />
          <stop offset="1" stopColor="#C5DCFF" />
        </linearGradient>
        <linearGradient id="paint0_linear_1536_7514" x1="233" y1="60" x2="150.61" y2="161.708" gradientUnits="userSpaceOnUse">
          <stop stopColor="#76D6FF" />
          <stop offset="1" stopColor="#7280FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const WalletBalance: React.FC<{
  changeBalance?: (value: string) => void
  currency_code: string
}> = ({
  changeBalance,
  currency_code
}) => {
    const t = useTranslate()
    const { data: user } = useGetIdentity<ICurrentUser>()

    const { data: accountsData, isLoading } = useList<IAccount>({
      resource: 'accounts',
      filters: [
        {
          field: "currency_code",
          operator: "eq",
          value: currency_code,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 1,
      },
    })
    const accounts = accountsData?.data ?? [];

    const balance = accounts.length ? formatAmount(accounts[0].balance) : '0.00';
    const currency = accounts.length ? formatCurrency(accounts[0]?.currency?.code) : '';

    useEffect(() => {
      accounts.length && changeBalance && changeBalance(accounts[0].balance)
    }, [accountsData])

    return (
      <Flex
        justify='space-between'
        align='center'
        style={{
          padding: '16px',
          borderRadius: 10,
          background: 'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%) border-box',
          border: '1px solid transparent',
        }}
      >
        <Flex
          align='center'
          gap={10}
        >
          <div
            style={{
              height: 14,
              width: 14,
              borderRadius: 60,
              background: 'linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%)',
            }}
          />
          <span
            children={<LogoBlack />}
            style={{
              height: 20,
              padding: '4px 0',
            }}
          />
          <Flex
            vertical
          >
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: '20.23px',
                color: '#303030',
              }}
            >
              Fortune Wallet
            </Text>
            <Text
              style={{
                height: 20,
                fontFamily: 'Jost',
                fontSize: 10,
                fontWeight: '400',
                lineHeight: '14.45px',
                color: '#AAAAAA',
              }}
            >
              {user?.phone && formatPhone(user.phone)}
            </Text>
          </Flex>
        </Flex>
        <Flex
          vertical
        >
          <Text
            style={{
              height: 20,
              fontFamily: 'Jost',
              fontSize: 10,
              fontWeight: '400',
              lineHeight: '14.45px',
              color: '#AAAAAA',
            }}
          >
            Доступный баланс
          </Text>
          <Text
            style={{
              height: 20,
              fontFamily: 'Jost',
              fontSize: 14,
              fontWeight: '700',
              lineHeight: '20.23px',
              color: '#303030',
            }}
          >
            {isLoading ? (
              <Skeleton.Input size='small' active />
            ) : (
              <Space size={1}>{currency}{balance}</Space>
            )}
          </Text>
        </Flex>
      </Flex>
    )
  }