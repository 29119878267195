export const ForwardIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1515_4039)">
        <path d="M4.32666 13.7807L5.50666 14.9607L12.1067 8.36065L5.50666 1.76065L4.32666 2.94065L9.74666 8.36065L4.32666 13.7807Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_1515_4039">
          <rect width="16" height="16" fill="white" transform="translate(0 0.360657)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1515_4091)">
        <path d="M5.99998 11.1406L3.21998 8.36064L2.27332 9.30064L5.99998 13.0273L14 5.02731L13.06 4.08731L5.99998 11.1406Z" fill="#00C364" />
      </g>
      <defs>
        <clipPath id="clip0_1515_4091">
          <rect width="16" height="16" fill="white" transform="translate(0 0.360657)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CloseIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1515_4097)">
        <path d="M12.6667 4.634L11.7267 3.694L8.00004 7.42067L4.27337 3.694L3.33337 4.634L7.06004 8.36067L3.33337 12.0873L4.27337 13.0273L8.00004 9.30067L11.7267 13.0273L12.6667 12.0873L8.94004 8.36067L12.6667 4.634Z" fill="#F28754" />
      </g>
      <defs>
        <clipPath id="clip0_1515_4097">
          <rect width="16" height="16" fill="white" transform="translate(0 0.360657)" />
        </clipPath>
      </defs>
    </svg>
  );
};