import React, { ReactNode } from "react";
import { Typography } from 'antd'

const { Text } = Typography

export const TextJost: React.FC<{
  copyable?: boolean
  fontSize?: number
  fontWeight?: string
  color?: string
  children: ReactNode
  style?: React.CSSProperties
}> = ({
  copyable = false,
  fontSize = 14,
  fontWeight = '400',
  color = '#303030',
  children,
  style = {},
}) => {
    const copyableConfig = {
      // icon: [<Copy/>, <Copied />],
      tooltips: ['', ''],
    }

    return (
      <Text
        copyable={copyable ? copyableConfig : false}
        children={children}
        style={{
          fontFamily: 'Jost',
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: color,
          ...style,
        }}
      />
    )
  }