import { useTranslate, useList } from '@refinedev/core'
import { Typography, Skeleton } from 'antd'
import { Link } from 'react-router-dom'

import { Paginator } from './paginator';
import { formatAmount, formatCurrency } from "../utils";
import { IAccount } from 'interfaces'

const { Text } = Typography

const Icon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1588_16865)">
        <path d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM12.5 20C8.08 20 4.5 16.42 4.5 12C4.5 7.58 8.08 4 12.5 4C16.92 4 20.5 7.58 20.5 12C20.5 16.42 16.92 20 12.5 20Z" fill="#303030" />
        <path d="M7.5 13.5C8.32843 13.5 9 12.8284 9 12C9 11.1716 8.32843 10.5 7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5Z" fill="#303030" />
        <path d="M12.5 13.5C13.3284 13.5 14 12.8284 14 12C14 11.1716 13.3284 10.5 12.5 10.5C11.6716 10.5 11 11.1716 11 12C11 12.8284 11.6716 13.5 12.5 13.5Z" fill="#323232" />
        <path d="M17.5 13.5C18.3284 13.5 19 12.8284 19 12C19 11.1716 18.3284 10.5 17.5 10.5C16.6716 10.5 16 11.1716 16 12C16 12.8284 16.6716 13.5 17.5 13.5Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_1588_16865">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

const balanceFontSize = (balance: string): number => {
  const len = balance.length;

  if (len <= 10) {
    return 32;
  }

  switch (len) {
    case 11:
      return 30;
    case 12:
      return 28;
    case 13:
      return 26;
    case 14:
      return 24;
    case 15:
      return 22;
    case 16:
      return 20;
    case 17:
      return 18;
    case 18:
      return 16;
    case 19:
      return 14;
    default:
      return 12;
  }
}

export const Balance: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  const t = useTranslate()

  const { data: accountsData, isLoading, isError } = useList<IAccount>({
    resource: 'accounts',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })
  const accounts = accountsData?.data ?? [];

  const balance = accounts.length ? formatAmount(accounts[0].balance) : '0.00';
  const currency = accounts.length ? formatCurrency(accounts[0]?.currency?.code) : '';

  return (
    <div
      style={Object.assign({
        height: 79,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }, style)}
    >
      <div
        style={{
          height: 17,
          textAlign: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Jost',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '17.34px',
            color: '#303030',
          }}
        >
          {t('components.balance.titles.wallet_balance')}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          height: 46,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton.Input active />
        ) : (
          <>
            <Text
              style={{
                height: 35,
                fontFamily: 'Jost',
                fontSize: 24,
                fontWeight: 500,
                lineHeight: '34.68px',
                color: '#303030',
              }}
            >
              {currency}
            </Text>
            <Text
              style={{
                height: 46,
                fontFamily: 'Jost',
                fontSize: balanceFontSize(balance),
                fontWeight: '800',
                lineHeight: '46.24px',
                color: '#303030',
                margin: '0 8px',
              }}
            >
              {balance}
            </Text>
            <Link
              to="/home"
              children={<Icon />}
            />
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Paginator />
      </div>
    </div >
  )
}