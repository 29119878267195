import { Show, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { ICard } from 'interfaces'
import AdminCardsTable from "pages/admin/cards/list_table";

export const CardsList: React.FC<any> = ({ userId }) => {
  const t = useTranslate()
  const { tableProps } = useTable<ICard>({
    resource: 'admin/cards',

    filters: {
      permanent: [
        {
          field: 'user_id',
          operator: 'eq',
          value: userId,
        },
      ]
    }
  })

  return (
    <Show
      title={t('pages.users.titles.cards')}
      headerButtons={() => <></>}
      canEdit={false}
      goBack={false}
      breadcrumb={false}
      wrapperProps={{ style: { marginTop: 20 } }}
    >
      <AdminCardsTable tableProps={tableProps} />
    </Show>
  )
}
