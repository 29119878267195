import { useTranslate } from '@refinedev/core'
import { SecretKey } from './secret_key'
import { AccessTokens } from './access_tokens'
import { Typography } from 'antd'

export const ApiTokens: React.FC = () => {
  const t = useTranslate()

  return (
    <>
      <Typography.Title level={3}>{t('pages.api_tokens.title')}</Typography.Title>
      <AccessTokens />
      <div style={{ height: 12 }} />
      <SecretKey />
    </>
  )
}
