import React, { ReactNode } from "react";

export const Ellipse: React.FC<{
  children?: ReactNode
  style?: React.CSSProperties
}> = ({
  children,
  style = {}
}) => {
    return (
      <span
        style={Object.assign({
          display: 'inline-block',
          width: 44,
          height: 44,
          padding: 8,
          background: 'linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(258.2deg, #76D6FF 0%, #7280FF 100%) border-box',
          border: '2px solid transparent',
          borderRadius: '50%',
        }, style)}
        children={children}
      />
    )
  }