export const HistoryIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6893)">
        <path d="M15.1 19.3701L16.1 21.1101C15.14 21.5501 14.09 21.84 13 21.9501V19.93C13.74 19.84 14.44 19.6501 15.1 19.3701ZM4.07005 13H2.05005C2.16005 14.1 2.45005 15.14 2.89005 16.1L4.63005 15.1C4.35005 14.44 4.16005 13.74 4.07005 13ZM15.1 4.63005L16.1 2.89005C15.14 2.45005 14.1 2.16005 13 2.05005V4.07005C13.74 4.16005 14.44 4.35005 15.1 4.63005ZM19.93 11H21.9501C21.84 9.90005 21.5501 8.86005 21.1101 7.90005L19.3701 8.90005C19.6501 9.56005 19.84 10.26 19.93 11ZM8.90005 19.3701L7.90005 21.1101C8.86005 21.5501 9.91005 21.84 11 21.9501V19.93C10.26 19.84 9.56005 19.6501 8.90005 19.3701ZM11 4.07005V2.05005C9.90005 2.16005 8.86005 2.45005 7.90005 2.89005L8.90005 4.63005C9.56005 4.35005 10.26 4.16005 11 4.07005ZM18.3601 7.17005L20.1 6.16005C19.4701 5.29005 18.7 4.52005 17.83 3.89005L16.82 5.63005C17.41 6.08005 17.9201 6.59005 18.3601 7.17005ZM4.63005 8.90005L2.89005 7.90005C2.45005 8.86005 2.16005 9.90005 2.05005 11H4.07005C4.16005 10.26 4.35005 9.56005 4.63005 8.90005ZM19.93 13C19.84 13.74 19.6501 14.44 19.3701 15.1L21.1101 16.1C21.5501 15.14 21.84 14.09 21.9501 13H19.93ZM16.83 18.3601L17.84 20.1C18.71 19.4701 19.4801 18.7 20.1101 17.83L18.3701 16.82C17.9201 17.41 17.41 17.9201 16.83 18.3601ZM7.17005 5.64005L6.17005 3.89005C5.29005 4.53005 4.53005 5.29005 3.90005 6.17005L5.64005 7.18005C6.08005 6.59005 6.59005 6.08005 7.17005 5.64005ZM5.64005 16.83L3.90005 17.83C4.53005 18.7 5.30005 19.4701 6.17005 20.1L7.18005 18.3601C6.59005 17.9201 6.08005 17.41 5.64005 16.83ZM13 7.00005H11V12.41L15.29 16.7L16.7 15.29L13 11.59V7.00005Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6893">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InvoicesIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6898)">
        <path d="M22 5V7H19V10H17V7H14V5H17V2H19V5H22ZM19 19H5V5H11V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13H19V19ZM15 13V17H17V13H15ZM11 17H13V9H11V17ZM9 17V11H7V17H9Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6898">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SupportIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_120_6903)">
        <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z" fill="#303030" />
      </g>
      <defs>
        <clipPath id="clip0_120_6903">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};