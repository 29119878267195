import React from "react";

export const AppIcon: React.FC = () => {
  return (
    <svg width="150" height="50" viewBox="0 0 468 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15_275)">
        <path d="M95.322 0.9823V20.0611H31.5041C24.8766 20.0611 19.504 25.4337 19.504 32.0611V39.1399H57.413V58.2187H19.504V114.374C13.551 109.076 8.78312 102.567 5.51561 95.2767C2.2481 87.9863 0.555443 80.0806 0.549561 72.0826V24.9823C0.549561 11.7275 11.2947 0.9823 24.5496 0.9823H95.322Z" fill="#76D6FF" />
        <path d="M114.277 0.9823C124.745 0.9823 133.231 9.46851 133.231 19.9368V72.0826C133.225 80.0806 131.532 87.9862 128.265 95.2767C124.997 102.567 120.23 109.076 114.277 114.374C109.528 118.602 104.116 122.008 98.2599 124.454L95.322 125.678L92.9211 126.68C87.6195 128.903 82.0561 130.431 76.3675 131.227C64.325 132.943 52.0456 131.372 40.8121 126.68L38.4111 125.678V77.2975H57.3656V111.878C63.6227 113.129 70.063 113.129 76.3201 111.878V39.1399H95.2746V104.644C100.987 101.371 105.736 96.6342 109.041 90.9152C112.345 85.1961 114.086 78.6984 114.087 72.0826V58.2187H114.277V0.9823V0.9823Z" fill="#7280FF" />
      </g>
      <path d="M340.636 113.036C340.636 113.631 340.721 114.225 340.891 114.819C341.103 115.414 341.401 115.945 341.783 116.412C342.165 116.879 342.653 117.261 343.247 117.558C343.884 117.855 344.627 118.004 345.476 118.004C346.198 118.004 347.005 117.834 347.897 117.494L349.298 123.29C348.406 123.545 347.472 123.757 346.495 123.927C345.561 124.054 344.733 124.118 344.012 124.118C341.04 124.118 338.598 123.247 336.688 121.507C334.82 119.723 333.885 117.006 333.885 113.355V99.4708H330.064V94.0574H333.885V87.434H340.636V94.0574H347.26V99.4708H340.636V113.036Z" fill="#303030" />
      <path d="M304.569 111.571C304.951 113.44 305.843 114.989 307.244 116.221C308.645 117.409 310.386 118.004 312.466 118.004C313.91 118.004 315.141 117.77 316.16 117.303C317.221 116.836 318.113 116.199 318.835 115.393L323.866 118.959C322.635 120.615 321.043 121.889 319.09 122.78C317.137 123.672 314.886 124.118 312.339 124.118C310.089 124.118 308.051 123.736 306.225 122.971C304.399 122.165 302.828 121.103 301.512 119.787C300.196 118.428 299.177 116.879 298.455 115.138C297.776 113.355 297.436 111.465 297.436 109.47C297.436 107.474 297.776 105.564 298.455 103.738C299.134 101.912 300.068 100.32 301.257 98.9613C302.489 97.5602 303.953 96.4563 305.652 95.6496C307.35 94.8429 309.239 94.4395 311.32 94.4395C313.485 94.4395 315.417 94.8641 317.115 95.7133C318.814 96.52 320.257 97.6451 321.446 99.0887C322.635 100.532 323.548 102.231 324.185 104.184C324.821 106.137 325.14 108.26 325.14 110.552V111.571H304.569ZM318.071 106.731C317.731 104.905 316.988 103.419 315.842 102.273C314.695 101.084 313.167 100.49 311.256 100.49C309.346 100.49 307.817 101.084 306.671 102.273C305.524 103.419 304.845 104.905 304.633 106.731H318.071Z" fill="#303030" />
      <path d="M290.898 113.036C290.898 114.65 290.962 116.39 291.089 118.258C291.217 120.084 291.599 121.761 292.236 123.29L285.485 123.736C285.103 123.099 284.805 122.356 284.593 121.507C284.423 120.615 284.296 119.702 284.211 118.768C284.126 117.834 284.062 116.879 284.02 115.902C284.02 114.883 284.02 113.928 284.02 113.036V80.301H290.898V113.036Z" fill="#303030" />
      <path d="M276.718 113.036C276.718 114.65 276.782 116.39 276.909 118.258C277.036 120.084 277.419 121.761 278.055 123.29L271.305 123.736C270.923 123.099 270.625 122.356 270.413 121.507C270.243 120.615 270.116 119.702 270.031 118.768C269.946 117.834 269.882 116.879 269.84 115.902C269.84 114.883 269.84 113.928 269.84 113.036V80.301H276.718V113.036Z" fill="#303030" />
      <path d="M246.368 124.118C244.33 124.118 242.504 123.736 240.891 122.971C239.32 122.165 237.961 121.103 236.815 119.787C235.711 118.428 234.862 116.879 234.267 115.138C233.673 113.355 233.376 111.465 233.376 109.47C233.376 107.474 233.673 105.564 234.267 103.738C234.862 101.912 235.711 100.32 236.815 98.9613C237.961 97.5602 239.32 96.4563 240.891 95.6496C242.504 94.8429 244.309 94.4395 246.304 94.4395C248.342 94.4395 250.21 94.8641 251.909 95.7133C253.649 96.52 255.072 97.6239 256.176 99.025V95.1401H262.863V123.545H256.176V119.851C255.072 121.209 253.649 122.271 251.909 123.035C250.21 123.757 248.363 124.118 246.368 124.118ZM248.533 100.936C247.344 100.936 246.262 101.169 245.285 101.636C244.351 102.103 243.523 102.74 242.801 103.547C242.122 104.311 241.591 105.203 241.209 106.222C240.827 107.241 240.636 108.302 240.636 109.406C240.636 110.552 240.827 111.614 241.209 112.59C241.591 113.567 242.122 114.437 242.801 115.202C243.523 115.923 244.372 116.497 245.349 116.921C246.325 117.346 247.387 117.558 248.533 117.558C249.807 117.558 250.932 117.324 251.909 116.857C252.885 116.348 253.692 115.711 254.329 114.947C255.008 114.14 255.518 113.248 255.857 112.272C256.239 111.295 256.43 110.34 256.43 109.406C256.43 108.217 256.239 107.113 255.857 106.094C255.475 105.075 254.923 104.184 254.201 103.419C253.522 102.655 252.694 102.061 251.718 101.636C250.741 101.169 249.68 100.936 248.533 100.936Z" fill="#303030" />
      <path d="M222.909 82.0843H231.634L214.12 122.653L203.102 101.381L192.212 122.653L174.634 82.0843H183.296L193.04 104.821L203.166 82.7848L213.292 104.821L222.909 82.0843Z" fill="#303030" />
      <path d="M438.817 48.04C439.348 50.6346 440.586 52.787 442.532 54.4971C444.478 56.1483 446.896 56.9738 449.785 56.9738C451.79 56.9738 453.5 56.6495 454.916 56.0008C456.39 55.3522 457.628 54.4676 458.631 53.3472L465.619 58.3007C463.909 60.6005 461.697 62.3696 458.985 63.6079C456.272 64.8463 453.147 65.4655 449.608 65.4655C446.483 65.4655 443.653 64.9347 441.117 63.8733C438.581 62.7529 436.399 61.2786 434.571 59.4506C432.743 57.5635 431.328 55.4111 430.325 52.9934C429.382 50.5167 428.91 47.8925 428.91 45.121C428.91 42.3494 429.382 39.6958 430.325 37.1601C431.269 34.6244 432.566 32.413 434.217 30.526C435.928 28.58 437.962 27.0468 440.321 25.9264C442.68 24.806 445.304 24.2458 448.193 24.2458C451.201 24.2458 453.884 24.8355 456.243 26.0148C458.601 27.1353 460.606 28.698 462.257 30.7029C463.909 32.7079 465.176 35.0667 466.061 37.7793C466.945 40.4919 467.388 43.4403 467.388 46.6247V48.04H438.817ZM457.569 41.3174C457.098 38.7817 456.066 36.7178 454.473 35.1256C452.881 33.4745 450.758 32.6489 448.105 32.6489C445.451 32.6489 443.328 33.4745 441.736 35.1256C440.144 36.7178 439.2 38.7817 438.905 41.3174H457.569Z" fill="#7280FF" />
      <path d="M420.758 65.5539H410.763V43.2634C410.763 41.9661 410.586 40.7278 410.232 39.5484C409.937 38.369 409.436 37.337 408.729 36.4525C408.08 35.5679 407.225 34.8603 406.163 34.3296C405.161 33.7988 403.923 33.5335 402.448 33.5335C401.151 33.5335 399.883 33.7988 398.645 34.3296C397.465 34.8603 396.404 35.5679 395.46 36.4525C394.576 37.337 393.868 38.369 393.337 39.5484C392.807 40.7278 392.541 41.9956 392.541 43.3519V65.5539H382.635V25.1303H392.011V30.9683C393.367 29.0223 395.136 27.4596 397.318 26.2802C399.5 25.1008 402.065 24.5111 405.013 24.5111C407.313 24.5111 409.436 24.8944 411.382 25.661C413.328 26.4277 414.979 27.5481 416.336 29.0223C417.751 30.4376 418.842 32.1772 419.608 34.2411C420.375 36.2461 420.758 38.5459 420.758 41.1405V65.5539Z" fill="#7280FF" />
      <path d="M335.298 24.5111H345.293V46.8016C345.293 48.0989 345.44 49.3373 345.735 50.5167C346.089 51.6961 346.59 52.7281 347.239 53.6126C347.947 54.4971 348.802 55.2048 349.804 55.7355C350.866 56.2662 352.133 56.5316 353.608 56.5316C354.964 56.5316 356.232 56.2662 357.411 55.7355C358.591 55.2048 359.623 54.4971 360.507 53.6126C361.451 52.7281 362.188 51.6961 362.718 50.5167C363.249 49.3373 363.515 48.0695 363.515 46.7132V24.5111H373.421V64.9347H364.045V59.0968C362.689 61.0427 360.92 62.6054 358.738 63.7848C356.556 64.9642 353.991 65.5539 351.042 65.5539C348.743 65.5539 346.62 65.1706 344.674 64.404C342.728 63.6374 341.047 62.5465 339.632 61.1312C338.276 59.657 337.214 57.8879 336.448 55.824C335.681 53.76 335.298 51.4602 335.298 48.9245V24.5111Z" fill="#7280FF" />
      <path d="M316.753 50.0744C316.753 50.9 316.871 51.7256 317.107 52.5511C317.401 53.3767 317.814 54.1138 318.345 54.7625C318.876 55.4112 319.554 55.9419 320.379 56.3547C321.264 56.7675 322.296 56.9739 323.475 56.9739C324.478 56.9739 325.598 56.738 326.837 56.2662L328.783 64.3156C327.544 64.6694 326.247 64.9642 324.891 65.2001C323.593 65.377 322.443 65.4655 321.441 65.4655C317.313 65.4655 313.922 64.2566 311.269 61.8388C308.674 59.3621 307.377 55.5881 307.377 50.5167V31.2337H302.069V23.7151H307.377V14.5158H316.753V23.7151H325.952V31.2337H316.753V50.0744Z" fill="#7280FF" />
      <path d="M297.189 34.2411C296.717 34.1821 296.275 34.1527 295.862 34.1527C295.508 34.1527 295.155 34.1527 294.801 34.1527C291.557 34.2706 289.199 35.45 287.724 37.6908C286.309 39.8727 285.601 42.7917 285.601 46.4478V65.5539H275.695V25.1303H285.071V30.8798C286.073 28.9339 287.4 27.4006 289.051 26.2802C290.761 25.1008 292.707 24.4522 294.889 24.3342C295.243 24.3342 295.597 24.3342 295.951 24.3342C296.363 24.3342 296.776 24.3637 297.189 24.4227V34.2411Z" fill="#7280FF" />
      <path d="M246.008 65.4655C242.883 65.4655 240.052 64.9347 237.517 63.8733C234.981 62.7529 232.799 61.2786 230.971 59.4506C229.143 57.5635 227.728 55.4111 226.725 52.9934C225.782 50.5167 225.31 47.8925 225.31 45.121C225.31 42.3494 225.811 39.6958 226.814 37.1601C227.816 34.6244 229.202 32.413 230.971 30.526C232.799 28.58 234.981 27.0468 237.517 25.9264C240.111 24.806 242.942 24.2458 246.008 24.2458C249.192 24.2458 252.052 24.8944 254.588 26.1918C257.183 27.4301 259.365 29.0813 261.134 31.1452C262.962 33.1502 264.348 35.391 265.291 37.8677C266.235 40.2855 266.706 42.7032 266.706 45.121C266.706 48.0105 266.205 50.7231 265.203 53.2588C264.259 55.7355 262.873 57.8879 261.045 59.7159C259.276 61.485 257.094 62.9003 254.5 63.9617C251.964 64.9642 249.133 65.4655 246.008 65.4655ZM246.008 33.2681C244.416 33.2681 242.971 33.5924 241.674 34.2411C240.377 34.8898 239.256 35.7743 238.313 36.8947C237.428 37.9562 236.72 39.1945 236.19 40.6098C235.659 42.0251 235.394 43.4993 235.394 45.0325C235.394 46.6247 235.659 48.0989 236.19 49.4552C236.72 50.8115 237.458 52.0204 238.401 53.0819C239.345 54.0843 240.465 54.8804 241.762 55.4701C243.06 56.0598 244.475 56.3547 246.008 56.3547C247.718 56.3547 249.222 56.0303 250.519 55.3817C251.876 54.674 252.996 53.7895 253.881 52.728C254.824 51.6076 255.532 50.3693 256.003 49.013C256.475 47.6567 256.711 46.3298 256.711 45.0325C256.711 43.3814 256.446 41.8482 255.915 40.4329C255.384 39.0176 254.647 37.7793 253.704 36.7178C252.819 35.6564 251.699 34.8308 250.342 34.2411C249.045 33.5924 247.6 33.2681 246.008 33.2681Z" fill="#7280FF" />
      <path d="M179.677 5.49347H218.243V16.4618H190.733V32.1182H215.058V43.175H190.733V65.4655H179.677V5.49347Z" fill="#7280FF" />
      <defs>
        <clipPath id="clip0_15_275">
          <rect width="132.681" height="130.912" fill="white" transform="translate(0.549561 0.9823)" />
        </clipPath>
      </defs>
    </svg>
  );
};
