import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  useTranslate,
} from '@refinedev/core'

import { List, useTable } from '@refinedev/antd'
import AdminEventsTable from './list_table'
import { IEvent } from 'interfaces'
import { Form, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const AdminEventsList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps, searchFormProps, filters } = useTable<IEvent>({
    resource: 'admin/events',

    onSearch: (params: any) => {
      const { operation_id, user_id, merchant_id, dispute_id } = params
      const filters: CrudFilters = []

      filters.push({
        field: 'operation_id',
        operator: 'eq',
        value: operation_id,
      })

      filters.push({
        field: 'user_id',
        operator: 'eq',
        value: user_id,
      })

      filters.push({
        field: 'merchant_id',
        operator: 'eq',
        value: merchant_id,
      })

      filters.push({
        field: 'dispute_id',
        operator: 'eq',
        value: dispute_id,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{}}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 10,
          }}>
          <Form.Item
            name="operation_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.admin_events.filter.operation_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="user_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.admin_events.filter.user_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="merchant_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.admin_events.filter.merchant_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
          <Form.Item
            name="dispute_id"
            style={{
              flexGrow: 1,
            }}
          >
            <Input
              placeholder={t('pages.admin_events.filter.dispute_id.placeholder')}
              prefix={<SearchOutlined />}
              allowClear
            />
          </Form.Item>
        </div>
      </Form>
      <AdminEventsTable tableProps={tableProps} />
    </List>
  )
}
