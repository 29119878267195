import { IResourceComponentsProps, useTranslate, useList } from '@refinedev/core'
import { ListButton, Create, useForm } from '@refinedev/antd'
import {
  Form,
  Space,
  Tag,
  InputNumber,
  Select,
  Row,
  Col,
  DatePicker,
  SelectProps
} from 'antd'
import { useEffect } from 'react'
import { tariffKindsOptions } from 'utils'
import { ITariff, IGateway } from 'interfaces'
import dayjs from 'dayjs'
import { GatewayType } from 'components/GatewayType'
import { GatewayDirection } from 'components/GatewayDirection'
import { GatewayStatus } from 'components/GatewayStatus'
import { TariffKind } from 'components/TariffKind'

export const AdminTariffCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { form, formProps, saveButtonProps } = useForm<ITariff>()

  const valid_from_not_used = Form.useWatch('valid_from_not_used', form)

  useEffect(() => {
    form.setFieldsValue({
      valid_from: valid_from_not_used ? valid_from_not_used.format('YYYY-MM-DD') : null,
    })
  }, [valid_from_not_used, form])

  const { data: gatewaysData, isLoading: isFetchingGateways } = useList<IGateway>({
    resource: 'admin/gateways',
    pagination: {
      current: 1,
      pageSize: 1000,
    },
  })

  const gatewayOptions: SelectProps['options'] = gatewaysData?.data.map((gateway) => ({
    label: (
      <Space size={1}>
        <Tag>{gateway?.currency?.code}</Tag>
        <GatewayDirection direction={gateway?.direction} />
        <GatewayType type={gateway?.payment_type} />
        <GatewayStatus status={gateway?.status} />
        <Tag>{gateway?.name}</Tag>
      </Space>
    ),
    desc: (gateway?.currency?.code ?? '') + (gateway?.direction ?? '') +
      (gateway?.payment_type ?? '') + (gateway?.name ?? ''),
    value: gateway.id,
  }))

  const headerButtons = () => {
    return (
      <ListButton resource="admin/tariffs" />
    )
  }

  return (
    <Create
      headerButtons={headerButtons}
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          kind: 'not_trusted_not_verified',
          valid_from_not_used: dayjs.utc().add(1, 'day'),
        }}
      >
        <Form.Item name="valid_from" rules={[{ required: true, },]} hidden />

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="kind"
              label={t('pages.admin_tariffs.fields.kind')}
              rules={[{ required: true, },]}
            >
              <Select
                options={tariffKindsOptions}
                optionRender={(option) => <TariffKind kind={option.data.value} />}
                labelRender={(option) => <TariffKind kind={option.value} />}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="valid_from_not_used"
              label={t('pages.admin_tariffs.fields.valid_from')}
              rules={[{ required: true, },]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                minDate={dayjs.utc()}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col md={12} xs={24}>
            <Form.Item
              name="gateway_id"
              label={t('pages.admin_tariffs.fields.gateway')}
              rules={[{ required: true, },]}
            >
              <Select
                defaultOpen
                options={gatewayOptions}
                allowClear
                showSearch
                filterOption={(input, option: any) => {
                  return (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                }}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="percentage_fee"
              label={t('pages.admin_tariffs.fields.percentage_fee')}
              rules={[{ required: true, },]}
            >
              <InputNumber
                type="number"
                decimalSeparator="."
                precision={2}
                min={0}
                max={100}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  )
}
